import React from 'react';
import { makeStyles, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, InputAdornment } from '@material-ui/core';
import { Book as BookIcon, FileCopy as FileCopyIcon, Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { Scope } from '@unform/core';

import CustomSelect from '../../../components/common/form/CustomSelect';
import CustomDatePicker from '../../../components/common/form/CustomDatePicker';
import { initLendBook } from '../lendBookActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  icon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  }
}));

function LendItems(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = React.useState(props.data !== undefined ? props.data.length <= 1 ? true : false : false);

  function add(index, item) {
    if (item !== undefined) {
      const allData = props.formRef.current.getData();
      item = allData.lendItems[index - 1];
    } else {
      item = props.bookList[0];
    }
    if (!props.readOnly)
      addOrRemoveItem(item, index);

    checkDisabled(false);
  }

  function remove(index) {
    if (props.data.length > 1)
      addOrRemoveItem(null, index);

    checkDisabled(true);
  }

  function addOrRemoveItem(item, index) {
    const newArray = [...props.data];
    item ? newArray.splice(index, 0, item) : newArray.splice(index, 1);

    let allData = props.formRef.current.getData();
    allData.lendItems = newArray;
    if (props.idLend)
      allData._id = props.idLend

    initLendBook(dispatch, allData);
  }

  function checkDisabled(removed) {
    let value;

    if (props.data.length === 2 && !removed) value = 0
    else value = 1

    props.data.length - value === 1 ?
      setDisabled(true) : setDisabled(false)
  }

  const renderRow = ({ data, name, bookList }) => {
    return data && data.map((item, index) => (
      <TableRow key={item._id} className={classes.containerRow}>
        <Scope path={`${name}[${index}]`}>
          <TableCell width='55%'>
            <CustomSelect
              name='book'
              variant='outlined'
              className={classes.formControl}
              label='Livro*'
              fullWidth
              size='small'
              options={bookList}
              defaultObj
            />
          </TableCell>
          <TableCell width='30%'>
            <CustomDatePicker
              className={classes.textField}
              size='small'
              fullWidth
              label='Data Devolução*'
              name='returnDate'
              type='text'
            />
          </TableCell>
        </Scope>
        <TableCell width='30%'>
          <IconButton aria-label='add' color='primary' size='small' onClick={() => add(index + 1)}>
            <AddIcon />
          </IconButton>
          {/* <IconButton aria-label='edit' color='secondary' size='small' style={{ marginLeft: 5 }} onClick={() => add(index + 1, data[index])}>
            <FileCopyIcon />
          </IconButton> */}
          <IconButton aria-label='delete' className={classes.icon} size='small' onClick={() => remove(index)} disabled={disabled}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <TableContainer>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            <TableCell>Livro</TableCell>
            <TableCell>Data Devolução</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRow(props)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LendItems;
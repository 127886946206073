import React, { useEffect } from 'react';
import { makeStyles, Paper, Tabs, IconButton, Typography, Button, CircularProgress, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  Add as AddIcon, List as ListIcon, Edit as EditIcon, ArrowBack as ArrowBackIcon,
  Print as PrintIcon, Send as SendIcon
} from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import { toastr } from 'react-redux-toastr';

import { TAB_SELECTED } from '../../utils/types';
import { init, sendPdfEmail, sortListByDate, setBalance } from './brotherBoxActions';
import { isEmpty, isArrayEmpty, currencyFormat, getGrandTotal, dateConvert, returnCorrectListName } from '../../utils/functions';
import PeopleList from './components/PeopleList';
import BrotherBoxList from './components/BrotherBoxList';
import BrotherBoxForm from './components/BrotherBoxForm';
import TabPanel from '../../components/common/Tab/components/TabPanel';
import TabHeader from '../../components/common/Tab/components/TabHeader';
import LabelAndIcon from '../../components/common/Tab/components/LabelAndIcon';
import ContentHeader from '../../components/common/template/ContentHeader';
import BrotherBoxReport from './components/BrotherBoxReport';
import SendEmailDialog from './components/SendEmailDialog';
import SendEmailForAll from './components/SendEmailForAll';
import { GROUPS } from '../../utils/consts';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  container: {
    // padding: theme.spacing(1),
    // backgroundColor: theme.palette.background.paper,
  },
  containerTab: {
    // padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    // backgroundColor: '#CCC',
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    flex: 1,
    overflow: 'auto'
  },
  spacer: {
    flexGrow: 1
  },
  actionButton: {
    marginRight: theme.spacing(1),
    minWidth: '100px'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  total: {
    marginLeft: theme.spacing(1)
  },
  containerTotal: {
    display: 'flex'
  }
}));

let newBrotherBox = {};

function BrotherBox() {
  const classes = useStyles();
  const data = useSelector(state => state.people.list);
  const value = useSelector(state => state.tab.selected);
  const brotherBox = useSelector(state => state.brotherBox);
  const dispatch = useDispatch();
  const theme = useTheme();
  const resetObj = { showViewer: false, label: 'Imprimir', disabled: false, open: false, isSendEmail: false };
  const [state, setState] = React.useState(resetObj);

  const handleClickOpen = () => {
    if (state.showViewer) {
      setState(state => ({
        ...state,
        showViewer: !state.showViewer,
        label: state.label === 'Imprimir' ? 'Fechar Impressão' : 'Imprimir'
      }));
    } else {
      setState(state => ({
        ...state,
        open: true,
        isSendEmail: false
      }));
    }
  };

  const handleClickSendEmail = () => {
    if (brotherBox.person.hasOwnProperty('address') &&
      brotherBox.person.address.hasOwnProperty('email') && !isEmpty(brotherBox.person.address.email)) {
      setState(state => ({
        ...state,
        open: true,
        isSendEmail: true
      }));
    } else {
      toastr.warning('Alerta', 'E-mail não encontrado no cadastro deste irmão.');
    }
  };

  const handleConfirm = (period, data) => {
    function total(data) {
      const getTotal = item => item.credit - item.debt;
      const somar = (acc, el) => acc + el

      const totalGeral = data
        .map(getTotal)
        .reduce(somar);

      return currencyFormat(totalGeral);
    }

    let newBrotherBoxList = [];
    let newPeriod = {};
    if (period === 'perPeriod') {
      // const startDate = new Date(data.initialDate);
      // const finalDate = new Date(data.finalDate);
      // newBrotherBoxList = brotherBox.person.brotherBox.filter(item => {
      //   let date = new Date(item.date);
      //   return date >= startDate && date <= finalDate
      // });
      newPeriod = calculatePerPeriod(data, brotherBox.person.brotherBox);
      newBrotherBoxList = newPeriod.periodList;
      delete newPeriod.periodList;
    } else {
      newBrotherBoxList = brotherBox.person.brotherBox;
      newBrotherBox.total = total(newBrotherBoxList);
    }
    newBrotherBox = convertValues(newBrotherBoxList);
    newBrotherBox['name'] = brotherBox.person.name;
    newBrotherBox['email'] = brotherBox.person.address.email;
    newBrotherBox['perPeriod'] = period === 'perPeriod' ? newPeriod : null;
    // const newBrotherBox = {
    //   name: brotherBox.person.name,
    //   email: brotherBox.person.address.email,
    //   brotherBox: period === 'perPeriod' ? newBrotherBoxList : brotherBox.person.brotherBox,
    //   perPeriod: period === 'perPeriod' ? newPeriod : null
    // }
    // console.log(newBrotherBox);
    if (state.isSendEmail) {
      sendPdfEmail(dispatch, newBrotherBox);
    } else {
      setState(state => ({
        ...state,
        showViewer: !state.showViewer,
        label: state.label === 'Imprimir' ? 'Fechar Impressão' : 'Imprimir'
      }));
    }

    setState(state => ({
      ...state,
      open: false
    }));
  };

  const handleClose = () => {
    setState(state => ({ ...state, open: false }));
  };

  const setValue = (newValue) => {
    dispatch({ type: TAB_SELECTED, payload: newValue })
  }

  useEffect(() => {
    init(dispatch, true);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function renderBrotherBox() {
    const handleBack = () => {
      setState(state => ({ ...state, ...resetObj }));
      init(dispatch, false);
    };

    let isPositiveTotal = false;
    const total = getTotal(brotherBox.person.brotherBox);

    function getTotal(data) {
      if (isArrayEmpty(data)) {
        const total = item => item.credit - item.debt;
        const sum = (acc, el) => acc + el

        const totalGeneral = data
          .map(total)
          .reduce(sum);

        isPositiveTotal = totalGeneral >= 0 ? true : false;
        return currencyFormat(totalGeneral);
      }
      return '';
    }

    if (!isEmpty(brotherBox.person) && isArrayEmpty(brotherBox.person.brotherBox) === 0) {
      state.disabled = true
    } else state.disabled = false;

    return brotherBox.showBrother ?
      <div className={classes.contentHeader}>
        <Grid container spacing={2}>
          <Grid item lg={5} md={5} sm={4} xs={12}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack} style={{ marginRight: 5 }}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant='h5' className={classes.title}>{brotherBox.person.name}</Typography>
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Button
              variant='contained'
              color='secondary'
              size='small'
              className={classes.actionButton}
              onClick={handleClickOpen}
              disabled={state.disabled}
            >
              <PrintIcon className={classes.icon} />
              {state.label}
            </Button>
            {brotherBox.loadingPdf ?
              <CircularProgress color='primary' size={24} />
              :
              <Button
                variant='contained'
                color='primary'
                size='small'
                className={classes.actionButton}
                onClick={handleClickSendEmail}
                disabled={state.disabled}
              >
                <SendIcon className={classes.icon} />
          Enviar PDF
        </Button>
            }
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={12}>
            <div className={classes.containerTotal}>
              <Typography variant='h4' className={classes.total}>
                Saldo Total:
          </Typography>
              <Typography variant='h4' className={classes.total} color={isPositiveTotal ? 'secondary' : 'error'}>
                {total}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      :
      <ContentHeader title='Caixa dos Irmãos' small='Lista' />
  }

  function calculatePerPeriod(period, data) {
    const startDate = new Date(period.initialDate);
    const finalDate = new Date(period.finalDate);
    const periodFilter = item => {
      let date = new Date(item.date);
      return date >= startDate && date <= finalDate
    }
    const previousFilter = item => {
      let date = new Date(item.date);
      return date <= startDate
    }
    let periodList = data
      .filter(periodFilter)
    const previousList = data
      .filter(previousFilter)

    periodList = setBalance(periodList);
    // console.log(previousList)
    const previousBalance = isArrayEmpty(previousList) ? getGrandTotal(previousList) : 0;
    // console.log(`Saldo anterior: ${currencyFormat(previousBalance)}`)
    // console.log(periodList)
    const periodBalance = isArrayEmpty(periodList) ? getGrandTotal(periodList) : [];
    // console.log(`Saldo período: ${currencyFormat(periodBalance)}`)
    // console.log(`Total: ${currencyFormat(periodBalance - previousBalance)}`)
    const newObj = {
      periodList,
      previousBalance: currencyFormat(previousBalance),
      periodBalance: currencyFormat(periodBalance),
      total: currencyFormat(periodBalance + previousBalance)
    }
    return newObj;
  }

  function convertValues(data) {
    let newObj = {};

    newObj.total = currencyFormat(isArrayEmpty(data) ? getGrandTotal(data) : 0);

    newObj.brotherBox = data.map(item => {
      let newItem = { ...item };
      newItem.balance = currencyFormat(item.balance);
      newItem.debt = currencyFormat(item.debt);
      newItem.credit = currencyFormat(item.credit);
      newItem.date = dateConvert(item.date);
      newItem.group = returnCorrectListName(item.group, GROUPS, 'value')

      delete newItem._id;
      delete newItem.forAll;

      return newItem;
    });
    return newObj;
  }

  return (
    <div className={classes.root}>
      {renderBrotherBox()}
      {state.showViewer ?
        <BrotherBoxReport data={newBrotherBox} />
        :
        <Paper square className={classes.container} color='default'>
          <div className={classes.containerTab}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons={window.innerWidth < 535 ? 'on' : 'auto'}
            >
              <TabHeader
                label={<LabelAndIcon icon={<ListIcon className={classes.icon} />} label='Listar' />}
                target='tabList'
              />
              <TabHeader
                label={<LabelAndIcon icon={<ListIcon className={classes.icon} />} label='Listar' />}
                target='tabListBorther'
              />
              <TabHeader
                label={<LabelAndIcon icon={<AddIcon className={classes.icon} />} label='Incluir' />}
                target='tabCreate'
              />
              <TabHeader
                label={<LabelAndIcon icon={<EditIcon className={classes.icon} />} label='Alterar' />}
                target='tabUpdate'
              />
              <TabHeader
                label={<LabelAndIcon icon={<AddIcon className={classes.icon} />} label='Incluir para Todos' />}
                target='tabCreateForEveryone'
              />
              <TabHeader
                label={<LabelAndIcon icon={<SendIcon className={classes.icon} />} label='Enviar para Todos' />}
                target='tabSendForEveryone'
              />
            </Tabs>
          </div>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <PeopleList data={data} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <BrotherBoxList />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <BrotherBoxForm submitLabel='Incluir' />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <BrotherBoxForm submitLabel='Alterar' />
            </TabPanel>
            <TabPanel value={value} index={4} dir={theme.direction}>
              <BrotherBoxForm submitLabel='Incluir' />
            </TabPanel>
            <TabPanel value={value} index={5} dir={theme.direction}>
              <SendEmailForAll submitLabel='Enviar' />
            </TabPanel>
          </SwipeableViews>
        </Paper>
      }
      <SendEmailDialog
        open={state.open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        data={brotherBox.person}
        isSendEmail={state.isSendEmail}
      />
    </div>
  );
}

export default BrotherBox;
import React from 'react';

import Routes from './routes/routes';
import Messages from './components/common/msg/messages';

function App() {
  return (
    <>
      <Routes />
      <Messages />
    </>
  );
}

export default App;

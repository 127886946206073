import moment from 'moment';
import locale from 'moment/locale/pt-br';

export function dateConvert(date, isHour) {
  if (date !== undefined) {
    const hour = 'DD/MM/YYYY - HH:mm';
    const normal = 'DD/MM/YYYY';
    return moment(date).locale('pt-br', locale).format(isHour ? hour : normal);
    // const formatter = new Intl.DateTimeFormat('pt-BR', {
    //   style: 'currency',
    //   currency: 'BRL'
    // });
    // return formatter.format(date);
  }
  return '';
}

export function currencyFormat(num) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
  return formatter.format(num);
}

export function replaceValue(value) {
  return value.toString().replace(/,/g, '.');
}

export function initDate() {
  const date = moment(Date.now()).format();

  return date;
}

export function initTable() {
  const script = document.createElement('script');

  script.src = `js/table.js`;
  script.async = true;

  document.body.appendChild(script);
}

export function msgRequired(item) {
  return `Campo ${item} é obrigatório!`
}

export function isEmpty(str) {
  return Object.keys(str).length === 0;
}

export function isArrayEmpty(array) {
  return Array.isArray(array) && array.length;
}

export function getGrandTotal(data) {
  const getTotal = item => item.credit - item.debt;
  const somar = (acc, el) => acc + el

  const totalGrand = data
    .map(getTotal)
    .reduce(somar);

  return totalGrand;
  // return currencyFormat(totalGrand);
}

export function compressArray(original) {
  var compressed = [];
  // make a copy of the input array
  var copy = original.slice(0);

  // first loop goes over every element
  for (var i = 0; i < original.length; i++) {

    var myCount = 0;
    // loop over every element in the copy and see if it's the same
    for (var w = 0; w < copy.length; w++) {
      if (original[i] == copy[w]) {
        // increase amount of times duplicate is found
        myCount++;
        // sets item to undefined
        delete copy[w];
      }
    }

    if (myCount > 0) {
      var a = new Object();
      a.value = original[i];
      a.count = myCount;
      compressed.push(a);
    }
  }

  return compressed;
};

export function hasOwnProperty(prop, object) {
  return object.hasOwnProperty(prop);
}

export function returnCorrectListName(id, list, name) {
  if (id) {
    const item = list.filter(item => item._id === id)[0];
    return item && item[name];
  }
  return '';
}

export function convertSize(size) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
}

export function existsContent(value) {
  if (!value) return false;
  if (Array.isArray(value) && value.length === 0) return false;
  if (typeof value === 'string' && !value.trim()) return false;

  return true;
}
import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

import Header from '../../../../components/common/report/Header';
import { SYSTEM_NAME } from '../../../../utils/consts';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'colum',
    // backgroundColor: '#E4E4E4',
    padding: 15,
    // width: '100%',
    // height: '100%',
  },
  table: {
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 20,
  },
  headerTxt: {
    fontSize: 12,
    marginLeft: 15
  },
  containerTotal: {
    flexDirection: 'row',
    marginTop: 5,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  containerTotais: {
    flexDirection: 'row',
    
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  total: {
    fontSize: 16,
    marginLeft: 5
  },
  tableBody: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  bodyTxt: {
    fontSize: 9,
    // marginRight: 10
  },
  section: {
    // margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const BrotherBoxReport = ({ data }) => {

  // console.log(data);

  function renderPerPeriod() {
    return (
      data.perPeriod !== null ?
        <>
          <View style={styles.containerTotal}>
            <Text style={styles.headerTxt}>Saldo Anterior: </Text>
            <Text style={styles.total}>{data.perPeriod.previousBalance}</Text>
          </View>
          <View style={styles.containerTotal}>
            <Text style={styles.headerTxt}>Saldo Período: </Text>
            <Text style={styles.total}>{data.perPeriod.periodBalance}</Text>
          </View>
        </>
        : <View />
    );
  }

  return (
    <PDFViewer height={600} width={1200}>
      <Document
        author={SYSTEM_NAME}
        title='Livro Caixa - Irmãos'
      >
        <Page size='A4' style={styles.page}>
          <Header title='Livro Caixa - Irmãos' subtitle={data.name} link={SYSTEM_NAME} />
          <Table data={data.brotherBox}>
            <TableHeader>
              <TableCell isHeader>Data</TableCell>
              <TableCell isHeader>Histórico</TableCell>
              <TableCell isHeader>Débito</TableCell>
              <TableCell isHeader>Crédito</TableCell>
              <TableCell isHeader>Saldo</TableCell>
              <TableCell isHeader>Observações</TableCell>
              <TableCell isHeader>Grupo</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell getContent={item => item.date} />
              <DataTableCell getContent={item => item.history} />
              <DataTableCell getContent={item => item.debt} />
              <DataTableCell getContent={item => item.credit} />
              <DataTableCell getContent={item => item.balance} />
              <DataTableCell getContent={item => item.observation} />
              <DataTableCell getContent={item => item.group} />
            </TableBody>
          </Table>
          <View style={styles.containerTotais}>
            {renderPerPeriod()}
            <View style={styles.containerTotal}>
              <Text style={styles.headerTxt}>Saldo Total: </Text>
              <Text style={styles.total}>{data.perPeriod !== null ? data.perPeriod.total : data.total}</Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default BrotherBoxReport;
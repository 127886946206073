import React from 'react';
import { makeStyles, CircularProgress, IconButton } from '@material-ui/core';
import {Edit as EditIcon, Delete as DeleteIcon, Description} from '@material-ui/icons';
import {useDispatch, useSelector} from 'react-redux';
import MUIDataTable from 'mui-datatables';

import {dateConvert, isArrayEmpty} from '../../../../utils/functions';
import {showPresence, showUpdate} from '../../calendarActions';
import { OPTIONS_TABLE } from '../../../../utils/consts';

const useStyles = makeStyles((theme) => ({
  containerProgress: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const options = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'eventAt',
    direction: 'asc'
  }
};

function List({ data }) {
  const classes = useStyles();
  const { user } = useSelector(state => state.signIn);
  const columns = [
    {
      name: 'eventAt',
      label: 'Data',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          return dateConvert(data[dataIndex].eventAt)
        }
      }
    },
    {
      name: 'session',
      label: 'Sessão',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'activity',
      label: 'Atividade',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'responsible',
      label: 'Responsável',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (value, tableMeta, updateValue) => {
        customBodyRenderLite: (dataIndex) => {
          const handleClick = () => {
            showUpdate(dispatch, data[dataIndex]);
          }

          const handleClickPresence = () => {
            showPresence(dispatch, data[dataIndex]);
          }

          if (user.userType === 'VENERAVEL' || user.userType === 'CHANCELER') {
            return (
                <>
                  <IconButton aria-label='edit' color='primary' size='small' onClick={handleClick}>
                    <EditIcon />
                  </IconButton>
                  <IconButton aria-label='edit' color='primary' size='small' onClick={handleClickPresence}>
                    <Description />
                  </IconButton>
                </>
            );
          }

          return (
            <IconButton aria-label='edit' color='primary' size='small' onClick={handleClick}>
              <EditIcon />
            </IconButton>
          );
        }
      }
    },
  ];

  const dispatch = useDispatch();

  const renderProgress = () => (
    <div className={classes.containerProgress}>
      <CircularProgress color='secondary' />
    </div>
  );

  return (
      data === null ?
      renderProgress() :
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
      />
  );
}

export default List;

import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import consts, { STATUS_BOOK_LIST } from '../../utils/consts';
import { LEND_BOOK_FETCHED, LEND_BOOK_INIT } from '../../utils/types';
import { BOOK_SELECTED } from '../../utils/consts';
import { showTabs, selectTab } from '../../components/common/Tab/tabActions';
import { compressArray } from '../../utils/functions';

const INITIAL_VALUES = { date: new Date().toISOString(), lendItems: [{ book: BOOK_SELECTED }] };

export function getList(dispatch, idUser) {
  axios({
    method: 'get',
    url: `${consts.API_URL}/lendBook`,
    params: {
      idUser
    }
  })
    .then(resp => {
      dispatch({ type: LEND_BOOK_FETCHED, payload: resp.data.data });
    })
    .catch(e => console.log(e));
}

export function create(values, isRequestBook, dispatch, idUser) {
  return submit(values, 'post', isRequestBook, dispatch, idUser);
}

export function update(values, isRequestBook, dispatch, idUser) {
  return submit(values, 'put', isRequestBook, dispatch, idUser);
}

export function remove(values, dispatch) {
  return submit(values, 'delete', dispatch);
}

function submit(values, method, isRequestBook, dispatch, idUser) {
  const id = values._id ? values._id : '';
  axios[method](`${consts.API_URL}/lendBook/${id}`, values)
    .then(resp => {
      toastr.success('Sucesso', 'Operação Realizada com sucesso.');
      init(dispatch, true, isRequestBook, idUser);
    })
    .catch(e => {
      console.log(e);
      toastr.error('Erro', e.hasOwnProperty('response') && e.response.data);
    });
}

export function init(dispatch, reload, isRequestBook, idUser) {
  dispatch([
    initLendBook(dispatch, INITIAL_VALUES),
    !isRequestBook ? showTabs(dispatch, 'tabList', 'tabCreate', 'tabRequestBook') : showTabs(dispatch, 'tabMyRequests', 'tabRequestBook'),
    !isRequestBook ? selectTab(dispatch, 0) : selectTab(dispatch, 3),
    reload && getList(dispatch, isRequestBook && idUser)
  ]);
}

export function showUpdate(dispatch, person) {
  dispatch([
    showTabs(dispatch, 'tabUpdate'),
    selectTab(dispatch, 2),
    initLendBook(dispatch, person)
  ]);
}

export function initLendBook(dispatch, value) {
  dispatch({ type: LEND_BOOK_INIT, payload: value });
}

export function updateStatus(values, dispatch, data) {
  const id = values._id ? values._id : '';
  axios.put(`${consts.API_URL}/lendBook/status/${id}`, values)
    .then(resp => {
      toastr.success('Sucesso', 'Operação Realizada com sucesso.');
      dispatch({ type: LEND_BOOK_FETCHED, payload: data });
    })
    .catch(e => {
      console.log(e);
      toastr.error('Erro', e.hasOwnProperty('response') && e.response.data);
    });
}

export async function updateAmountBook(data, dispatch, dataDefault) {
  const newCompress = compressArray(data.lendItems.map(item => item.book._id));
  for (let i = 0; i < newCompress.length; i++) {
    const { value: id, count: amount } = newCompress[i];
    try {
      // const test = await axios.get(`${consts.API_URL}/books/`);
      // console.log(test.status, test.status === 200, { status: STATUS_BOOK_LIST[3]._id });
      const currentBook = data.lendItems.filter(item => item.book._id === id)[0].book;
      const newTotalAmount = currentBook.amount + amount;
      // console.log(currentBook.title, `, current: ${currentBook.amount}, `, newTotalAmount);

      await axios.put(`${consts.API_URL}/lendBook/status/${data._id}`, { status: STATUS_BOOK_LIST[3]._id});
      await axios.put(`${consts.API_URL}/books/amount/${id}`, { amount: newTotalAmount });

    } catch (e) {
      console.log(e);
    }
  }
  toastr.success('Sucesso', 'Operação Realizada com sucesso.');
  dispatch({ type: LEND_BOOK_FETCHED, payload: dataDefault });
}
import api from '../../services/api';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import consts from '../../utils/consts';
import { BOOK_FETCHED, BOOK_INIT } from '../../utils/types';

import { showTabs, selectTab } from '../../components/common/Tab/tabActions';

export function getList(dispatch, userLevel, isAdm) {
  axios({
    method: 'get',
    url: `${consts.API_URL}/books`,
    params: {
      level: isAdm ? userLevel : null
    }
  })
    .then(resp => {
      dispatch({ type: BOOK_FETCHED, payload: resp });
    })
    .catch(e => console.log(e));
}

export function create(values, dispatch) {
  return submit(values, 'post', dispatch);
}

export function update(values, dispatch) {
  return submit(values, 'put', dispatch);
}

export function remove(values, dispatch) {
  return submit(values, 'delete', dispatch);
}

function submit(values, method, dispatch) {
  const id = values._id ? values._id : '';
  axios[method](`${consts.API_URL}/books/${id}`, values)
    .then(resp => {
      toastr.success('Sucesso', 'Operação Realizada com sucesso.');
      init(dispatch, true);
    })
    .catch(e => {
      toastr.error('Erro', e.response.data);
    });
}

export function init(dispatch, reload) {
  dispatch([
    initBook(dispatch, {}),
    showTabs(dispatch, 'tabList', 'tabCreate'),
    selectTab(dispatch, 0),
    reload && getList(dispatch)
  ]);
}

export function showUpdate(dispatch, book) {
  dispatch([
    showTabs(dispatch, 'tabUpdate'),
    selectTab(dispatch, 2),
    initBook(dispatch, book)
  ]);
}

function initBook(dispatch, value) {
  dispatch({ type: BOOK_INIT, payload: value });
}
export const SIGN_IN = 'sign_in';
export const LOGIN_MODE = 'login_mode';
export const TOKEN_VALIDATED = 'token_validated';
export const TAB_SELECTED = 'tab_selected';
export const TAB_SHOWED = 'tab_showed';
export const BOOK_FETCHED = 'book_fetched';
export const BOOK_INIT = 'book_init';
export const PEOPLE_FETCHED = 'people_fetched';
export const PEOPLE_FETCHED_TRASH = 'people_fetched_trash'
export const PEOPLE_INIT = 'people_init';
export const LEND_BOOK_FETCHED = 'lend_book_fetched';
export const LEND_BOOK_INIT = 'lend_book_init';
export const BROTHER_BOX_FETCHED = 'brother_box_fetched';
export const BROTHER_BOX_INIT = 'brother_box_init';
export const LOADING_PDF = 'loading_pdf';
export const SHOW_ERRORS_SEND_EMAILS = 'show_errors_send_emails';
export const PERSON_BOX_INIT = 'person_box_init';
export const CLIENT_FETCHED = 'client_fetched';
export const CLIENT_INIT = 'client_init';
export const PROVIDER_FETCHED = 'provider_fetched';
export const PROVIDER_INIT = 'provider_init';
export const ACCOUNT_RECEIVABLE_FETCHED = 'account_receivable_fetched';
export const ACCOUNT_RECEIVABLE_INIT = 'account_receivable_init';
export const ACCOUNT_PAYABLE_FETCHED = 'account_payable_fetched';
export const ACCOUNT_PAYABLE_INIT = 'account_payable_init';
export const ACCOUNTS_MODE = 'account_mode';
export const MY_FINANCIAL_FETCHED = 'my_financial_fetched';
export const WORK_FETCHED = 'work_fetched';
export const WORK_INIT = 'work_init';
export const CALENDAR_FETCHED = 'calendar_fetched';
export const CALENDAR_INIT = 'calendar_init';
export const CALENDAR_PRESENCE_FETCHED = 'calendar_presence_fetched';
export const PLAYLIST_FETCHED = 'playlist_fetched';
export const PLAYLIST_INIT = 'playlist_init';

import React from 'react';
import { makeStyles, Grid, InputAdornment, IconButton } from '@material-ui/core';
import { Scope } from '@unform/core';
import { Lock, Visibility, VisibilityOff } from '@material-ui/icons';

import CustomTextField from '../../../../../components/common/form/CustomTextField';
import CustomSelect from '../../../../../components/common/form/CustomSelect';
import { ACCESS_USER_TYPES } from '../../../../../utils/consts';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    formControl: {
      minWidth: 120,
    },
  },
}));

function AccessData({ isNew, isUserDefault = false }) {
  const classes = useStyles();
  const [utils, setUtils] = React.useState({ showPassword: false, showConfirmPassword: false });

  function handleClickShowPassword() {
    setUtils({ ...utils, showPassword: !utils.showPassword });
  }

  function handleClickShowConfirmPassword() {
    setUtils({ ...utils, showConfirmPassword: !utils.showConfirmPassword });
  }

  return (
    <div className={classes.root}>
      <Scope path='accessData'>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <CustomTextField size='small' fullWidth label='E-mail' name='email' type='text' variant='outlined' />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomTextField
              fullWidth
              label={isNew ? 'Senha' : 'Nova Senha'}
              name='newPassword'
              // name={isNew ? 'password' : 'newPassword'}
              type={utils.showPassword ? 'text' : 'password'}
              variant='outlined'
              size='small'
              InputProps={{
                // startAdornment: (
                //   <InputAdornment position='start'>
                //     <Lock />
                //   </InputAdornment>
                // ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                    >
                      {utils.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )

              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomTextField
              fullWidth
              label={isNew ? 'Confirmação de Senha' : 'Nova Confirmação de Senha'}
              name={isNew ? 'confirmPassword' : 'newConfirmPassword'}
              type={utils.showConfirmPassword ? 'text' : 'password'}
              variant='outlined'
              size='small'
              InputProps={{
                // startAdornment: (
                //   <InputAdornment position='start'>
                //     <Lock />
                //   </InputAdornment>
                // ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowConfirmPassword}
                    >
                      {utils.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          {/* {!isNew &&
            <Grid item md={4} xs={12}>
              <CustomTextField
                fullWidth
                label='Senha Atual'
                name='currentPassword'
                type='password'
                variant='outlined'
                size='small'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Lock />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          } */}
          {!isUserDefault &&
            <Grid item md={3} xs={12}>
              <CustomSelect
                name='userType'
                variant='outlined'
                className={classes.formControl}
                label='Tipo de Usuário'
                fullWidth
                size='small'
                options={ACCESS_USER_TYPES}
              />
            </Grid>
          }
        </Grid>
      </Scope>
    </div>
  );
}

export default AccessData;
import React, {useEffect} from 'react';
import {makeStyles, CircularProgress, IconButton, Divider, CardActions, Button, Typography} from '@material-ui/core';
import {Edit as EditIcon, Delete as DeleteIcon, Description, Check, Remove} from '@material-ui/icons';
import {useDispatch, useSelector} from 'react-redux';
import MUIDataTable from 'mui-datatables';

import {dateConvert, isArrayEmpty} from '../../../../utils/functions';
import {addPresence, getListPresence, init, removePresence, showPresence, showUpdate} from '../../calendarActions';
import { OPTIONS_TABLE } from '../../../../utils/consts';

const useStyles = makeStyles((theme) => ({
  containerProgress: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const options = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'title',
    direction: 'asc'
  }
};

function List({ data }) {
  const classes = useStyles();
  const { user } = useSelector(state => state.signIn);
  const calendar = useSelector(state => state.calendar.calendar);
  const presences = useSelector(state => state.calendar.presences);

  const columns = [
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (value, tableMeta, updateValue) => {
        customBodyRenderLite: (dataIndex) => {
          const personId = data[dataIndex]._id;

          let isExist = false
          if (presences) {
            presences.find(presence => {
              if (presence.person === personId) isExist = true;
            })
          }

          const handleClick = () => {
            isExist ? removePresence(dispatch, calendar._id, personId) : addPresence(dispatch, calendar._id, personId)
          }

          return (
            <IconButton aria-label='edit' color='primary' size='small' onClick={handleClick}>
              { isExist ? (<Remove />) : (<Check />)}
            </IconButton>
          );
        }
      }
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (calendar && calendar._id) getListPresence(dispatch, calendar._id)
  }, [calendar])

  const renderProgress = () => (
    <div className={classes.containerProgress}>
      <CircularProgress color='secondary' />
    </div>
  );

  function handleCancel() {
    init(dispatch, true);
  }

  if (user.userType === 'VENERAVEL' || user.userType === 'CHANCELER' || user.userType === 'SECRETARIO') {
    return (
        <>
          {data === null ?
              renderProgress() :
              <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}
              />
          }
            <Divider />
            <CardActions className={classes.cardActions}>
              <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  className={classes.btn}
                  onClick={handleCancel}
              >
                Voltar
              </Button>
            </CardActions>
        </>
    );
  }
  return null;
}

export default List;

import React, {useEffect, useState} from 'react';
import {makeStyles, Typography, Button, Grid, IconButton, CircularProgress} from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import consts, { OPTIONS_TABLE } from '../../utils/consts';
import {useDispatch, useSelector} from "react-redux";
import {dateConvert} from "../../utils/functions";
import {init as initCalendar} from "../Calendar/calendarActions";
import {init as initPlaylist} from "../Playlist/playlistActions";
import {showPlayer, showUpdate} from "../Playlist/playlistActions";
import {Edit as EditIcon, PlayArrow} from "@material-ui/icons";
import Playlist from "react-mp3-player";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    // backgroundColor: 'tomato'
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: '16px'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));


const optionsCalendar = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'title',
    direction: 'asc'
  }
};

const optionsPlaylist = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'title',
    direction: 'asc'
  }
};

function Dashboard() {
  const classes = useStyles();
  const calendarList = useSelector(state => state.calendar.list);
  const playlistList = useSelector(state => state.playlist.list);
  const dispatch = useDispatch();
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);


  const playlistOverideStylingOpts = {
    offset : {
    },
    breakpoint : {
      maxWidth : 768
    }
  };

  const columnsCalendar = [
    {
      name: 'eventAt',
      label: 'Data',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          return dateConvert(calendarList[dataIndex].eventAt)
        }
      }
    },
    {
      name: 'session',
      label: 'Sessão',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'activity',
      label: 'Atividade',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'responsible',
      label: 'Responsável',
      options: {
        filter: true,
        sort: true,
      }
    },
  ];

  const columnsPlaylist = [
    {
      name: 'description',
      label: 'Descrição',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'activity',
      label: 'Quantidade de Músicas',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          return playlistList[dataIndex].songs.length
        },
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (value, tableMeta, updateValue) => {
        customBodyRenderLite: (dataIndex) => {

          const handleClickPlay = () => {
            setSelectedPlaylist(playlistList[dataIndex]);
          }

          return (
              <>
                <IconButton aria-label='edit' color='primary' size='small' onClick={handleClickPlay}>
                  <PlayArrow />
                </IconButton>
              </>
          );
        }
      }
    },
  ];


  useEffect(() => {
    initCalendar(dispatch, true);
    initPlaylist(dispatch, true);
  }, []);


  const renderProgress = () => (
      <div className={classes.containerProgress}>
        <CircularProgress color='secondary' />
      </div>
  );

  return (
    <div className={classes.root}>
      {/* <Typography variant="h6" className={classes.labelText}>
        Dashboard
      </Typography> */}
      {/* {renderDoc()} */}
      <Grid item md={12} xs={12}>
        {calendarList === null ?
            renderProgress() :
            <MUIDataTable
                title='Calendario'
                data={calendarList}
                columns={columnsCalendar}
                options={optionsCalendar}
            />}
      </Grid>
      <Grid item md={12} xs={12}>
        {playlistList === null ?
            renderProgress() :
            <MUIDataTable
                title='Playlist'
                data={playlistList}
                columns={columnsPlaylist}
                options={optionsPlaylist}
            />}
      </Grid>
      <Grid item md={12} xs={12}>
        {selectedPlaylist && (
            <Playlist tracks={selectedPlaylist.songs.map(song => {
              return {
                img: 'https://icon-library.net/images/music-icon-transparent/music-icon-transparent-11.jpg',
                name:song.filename,
                src: `${consts.API_URL}/playlists-song/${song.key}`
              }
            })} opts={playlistOverideStylingOpts}/>
        )}
      </Grid>
      {/* <Grid item md={12} xs={12}>
        {<FunctionalComponent />}
      </Grid> */}
    </div>
  );
}

export default Dashboard;

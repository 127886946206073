import { PEOPLE_FETCHED, PEOPLE_INIT, PEOPLE_FETCHED_TRASH } from '../../utils/types';

const INITIAL_STATE = { list: [], listTrash: null, person: {} };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PEOPLE_FETCHED:
      return { ...state, list: action.payload.data.data };
    case PEOPLE_FETCHED_TRASH:
      return { ...state, listTrash: action.payload.data.data };
    case PEOPLE_INIT:
      return { ...state, person: action.payload };
    default:
      return state;
  }
};

import { SIGN_IN, TOKEN_VALIDATED, LOGIN_MODE } from '../../utils/types';

const userKey = '_project-gm_user';

const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem(userKey)),
  validToken: false,
  loginMode: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      localStorage.setItem(userKey, JSON.stringify(action.payload));
      return { ...state, user: action.payload, validToken: true };
    case TOKEN_VALIDATED:
      if (action.payload) {
        return { ...state, validToken: true };
      }
      localStorage.removeItem(userKey);
      return { ...state, validToken: false, user: null };
    case LOGIN_MODE:
      return { ...state, loginMode: action.payload };
    default:
      return state;
  }
};

import React, { useRef } from 'react';
import { makeStyles, Card, CardContent, Button, Typography, Avatar, Link, InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import { Email, Lock, Visibility, VisibilityOff } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { login } from '../SignIn/signInActions';

import CustomTextField from '../../components/common/form/CustomTextField';
import { SYSTEM_NAME } from '../../utils/consts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '1120',
    height: '100vh',
    margin: theme.spacing(0, 'auto'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 500
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  btn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // backgroundColor: theme.palette.primary.light
  },
  containerAvatar: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1)
  },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14)
  },
}));

function SignIn() {
  const classes = useStyles();
  const [utils, setUtils] = React.useState({
    showPassword: false,
    loading: false,
    disabled: false
  });
  const formRef = useRef(null);
  const dispatch = useDispatch();

  async function handleSubmit(data) {
    try {
      const shema = Yup.object().shape({
        email: Yup.string().required('O e-mail é obrigatório').email('E-mail inválido'),
        password: Yup.string().required('A senha é obrigatória'),
      });

      await shema.validate(data, { abortEarly: false });

      setUtils({ ...utils, loading: true, disabled: true });

      login(data, dispatch)
      // console.log(data);
      formRef.current.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage = {};

        err.inner.forEach(error => {
          errorMessage[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessage);
      }
    }
    setUtils({ ...utils, loading: false, disabled: false });
    // setTimeout(() => setUtils({ ...utils, loading: false, disabled: false }), 3000);
  }

  function handleClickShowPassword() {
    setUtils({ ...utils, showPassword: !utils.showPassword });
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <div className={classes.root}>
        <Card className={classes.container}>
          <CardContent>
            <Typography variant='h2' className={classes.title}>{SYSTEM_NAME}</Typography>
            <div className={classes.containerAvatar}>
              <Avatar src={require('../../assets/images/user.jpg')} className={classes.avatar} />
            </div>
            <CustomTextField
              className={classes.textField}
              fullWidth
              label='E-mail'
              name='email'
              type='text'
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
            <CustomTextField
              className={classes.textField}
              fullWidth
              label='Senha'
              name='password'
              type={utils.showPassword ? 'text' : 'password'}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                    >
                      {utils.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              variant='contained'
              color='primary'
              fullWidth
              size='large'
              className={classes.btn}
              disabled={utils.disabled}
              type='submit'
            >
              {utils.loading ?
                <CircularProgress color='primary' size={24} /> :
                <Typography variant='button' color='inherit'>Entrar</Typography>}
            </Button>
            {/* <Typography variant='body1' color='textSecondary'>
              Não possui conta? <Link
                component={RouterLink}
                to='/sign-up'
                variant='h6'
              >
                Cadastre-se
                            </Link>
            </Typography> */}
          </CardContent>
        </Card>
      </div>
    </Form>
  );
}

export default SignIn;
import { PLAYLIST_FETCHED, PLAYLIST_INIT } from '../../utils/types';

const INITIAL_STATE = { list: null, playlist: {} };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PLAYLIST_FETCHED:
            return { ...state, list: action.payload.data.data };
        case PLAYLIST_INIT:
            return { ...state, playlist: action.payload };
        default:
            return state;
    }
};

import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Scope } from '@unform/core';

import CustomTextField from '../../../../../components/common/form/CustomTextField';
import CustomDatePicker from '../../../../../components/common/form/CustomDatePicker';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

function Spouse() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Scope path='spouse'>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <CustomTextField size='small' fullWidth label='Cônjuge' name='spouseName' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomDatePicker size='small' fullWidth label='Data de Nascimento' name='dateOfBirth' type='text' isBirthday />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomDatePicker size='small' fullWidth label='Data do Casamento' name='weddingDate' type='text' isBirthday />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Profissão' name='profession' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Cargo' name='post' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Função' name='function' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Empresa' name='company' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Celular' name='cellphone' type='text' variant='outlined' mask='cellphone' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Telefone' name='phone' type='text' variant='outlined' mask='phone' />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomTextField size='small' fullWidth label='E-mail' name='email' type='text' variant='outlined' />
        </Grid>
      </Grid>
      </Scope>
    </div>
  );
}

export default Spouse;
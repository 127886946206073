import React from 'react';
import { makeStyles, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';

import { dateConvert, currencyFormat, isArrayEmpty, returnCorrectListName } from '../../../../utils/functions';
import { setBalance } from '../../../BrotherBox/brotherBoxActions';
import { GROUPS } from '../../../../utils/consts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 505,
  },
  icon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
  text: {
    marginTop: theme.spacing(1)
  }
}));

function List({ data }) {
  const classes = useStyles();
  
  const renderRow = () => {
    return isArrayEmpty(data) ? setBalance(data).map(brotherBox => (
      <TableRow key={brotherBox._id} hover>
        <TableCell>{dateConvert(brotherBox.date)}</TableCell>
        <TableCell>{brotherBox.history}</TableCell>
        <TableCell>{brotherBox.observation}</TableCell>
        <TableCell>{returnCorrectListName(brotherBox.group, GROUPS, 'value')}</TableCell>
        <TableCell>{currencyFormat(brotherBox.debt)}</TableCell>
        <TableCell>{currencyFormat(brotherBox.credit)}</TableCell>
        <TableCell>{currencyFormat(brotherBox.balance)}</TableCell>
      </TableRow>
    )) :
      <TableRow>
        <TableCell>
          <Typography variant='h5' className={classes.text}>Você não possuí registros.</Typography>
        </TableCell>
      </TableRow>
  }

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Histórico</TableCell>
            <TableCell>Obs</TableCell>
            <TableCell>Grupo</TableCell>
            <TableCell>Débito</TableCell>
            <TableCell>Crédito</TableCell>
            <TableCell>Saldo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRow()}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default List;
import { WORK_FETCHED, WORK_INIT } from '../../utils/types';

const INITIAL_STATE = { list: [], work: {} };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case WORK_FETCHED:
            return { ...state, list: action.payload.data.data };
        case WORK_INIT:
            return { ...state, work: action.payload };
        default:
            return state;
    }
};

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

function AuthRoutes() {
    return (
        <Router>
            <Switch>
                {/* <Route exact path='/' component={SignIn} /> */}
                <Route
                    path='/sign-in'
                    component={SignIn}
                />
                <Route
                    path='/sign-up'
                    component={SignUp}
                />
                <Route path='*' component={SignIn} />
            </Switch>
        </Router>
    );
}

export default AuthRoutes;
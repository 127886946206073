import React, { useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { getPerson } from './myFinancialActions';
import MyBoxList from './components/MyBoxList';
import ContentHeader from '../../components/common/template/ContentHeader';
import { isArrayEmpty, currencyFormat } from '../../utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  containerTab: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  containerTotal: {
    display: 'flex'
  },
  total: {
    marginLeft: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
}));

function MyFinancial() {
  const classes = useStyles();
  const person = useSelector(state => state.myFinancial.person);
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.signIn);

  useEffect(() => {
    getPerson(dispatch, user.id);
  }, []);

  let isPositiveTotal = false;
  const total = getTotal(person.brotherBox);

  function getTotal(data) {
    if (isArrayEmpty(data)) {
      const total = item => item.credit - item.debt;
      const sum = (acc, el) => acc + el

      const totalGeneral = data
        .map(total)
        .reduce(sum);

      isPositiveTotal = totalGeneral > 0 ? true : false;
      return currencyFormat(totalGeneral);
    }
    return '';
  }

  return (
    <div className={classes.root}>
      <ContentHeader title='Caixa' small='Meu Financeiro' />
      <Typography variant='h5'>{person.name}</Typography>
      <div className={classes.containerTab}>
        <MyBoxList data={person.brotherBox} />
      </div>
      <div className={classes.containerTotal}>
        <span className={classes.spacer} />
        <Typography variant='h4' className={classes.total}>
          Saldo Total:
          </Typography>
        <Typography variant='h4' className={classes.total} color={isPositiveTotal ? 'secondary' : 'error'}>
          {total}
        </Typography>
      </div>
    </div>
  );
}

export default MyFinancial;
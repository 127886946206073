import React from 'react';
import { makeStyles, CircularProgress, IconButton } from '@material-ui/core';
import { Restore as RestoreIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import { isArrayEmpty } from '../../../../utils/functions';
import { OPTIONS_TABLE } from '../../../../utils/consts';
import {showUpdate, remove, restore} from '../../peopleActions';
import CustomDialog from '../../../../components/common/form/Dialog';

const useStyles = makeStyles((theme) => ({
  containerProgress: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  containerBtn: {
    display: 'flex'
  },
  icon: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main,
  },
}));

const options = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'name',
    direction: 'asc'
  },
  rowsPerPage: 100
};

function List({ data }) {
  const classes = useStyles();
  const columns = [
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'level',
      label: 'Grau',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'cpfCnpj',
      label: 'CPF/CNPJ',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const handleClickDelete = () => {
            setItem(data[dataIndex]);
            setOpen(true);
          }

          return (
            <div className={classes.containerBtn}>
              <IconButton aria-label='delete' color='secondary' size='small' onClick={handleClickDelete} className={classes.icon}>
                <RestoreIcon />
              </IconButton>
            </div>
          );
        }
      }
    },
  ];
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState({});

  const handleConfirm = () => {
    restore(item, dispatch);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderProgress = () => (
    <div className={classes.containerProgress}>
      <CircularProgress color='secondary' />
    </div>
  );

  return (
      data === null ?
      renderProgress() :
      <div>
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
        />
        <CustomDialog open={open} handleClose={handleClose} handleConfirm={handleConfirm} context='Deseja realmente restaurar esse irmão?' />
      </div>
  );
}

export default List;

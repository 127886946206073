import React from 'react';
import { Avatar, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: 60,
        height: 60
    },
    name: {
        marginTop: theme.spacing(1)
    }
}));

function Profile(props) {
    const { className, ...rest } = props;
    const classes = useStyles();
    const user = useSelector(state => state.signIn.user);
    // const user = {
    //     name: 'Débora Campos',
    //     avatar: '/images/avatars/avatar_11.png',
    //     bio: 'Brain Director'
    // };

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Avatar
                alt='Person'
                className={classes.avatar}
                // src={require('../../../../../assets/images/avatar_11.png')}
                src={user.avatar}
            />
            <Typography className={classes.name} variant='h4'>
                {user.name}
            </Typography>
            <Typography variant='body2'>{user.email}</Typography>
        </div>
    );
}

Profile.propType = {
    className: PropTypes.string
};

export default Profile;
import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    containerTab: {
        display: 'flex',
        justifyContent: 'center'
    },
    icon: {
        marginRight: theme.spacing(1)
    }
}));

function LabelAndIcon({ icon, label }) {
    const classes = useStyles();

    return (
        <div className={classes.containerTab}>
            {icon}
            {label}
        </div>
    );
}

export default LabelAndIcon;
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

function CustomDialog({ open, handleClose, handleConfirm, context, children }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-labelledby='draggable-dialog-title'
      >
        <DialogTitle id='draggable-dialog-title'>
          Confirmação
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {context}
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color='secondary'>Cancelar</Button>
          <Button onClick={handleConfirm} color='primary'>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CustomDialog;
import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Radio,
  RadioGroup, FormControlLabel, FormControl, FormLabel, makeStyles, Typography
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  containerRadio: {
    marginBottom: theme.spacing(1)
  },
  containerPeriod: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4)
  },
  until: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2)
  }
}));

function SendEmailDialog({ open, handleClose, handleConfirm, data, isSendEmail }) {
  const classes = useStyles();
  const [period, setPeriod] = React.useState('all');
  const today = new Date();
  const [initialDate, setInitialDate] = React.useState(new Date(today.getFullYear(), today.getMonth(), 1));
  const [finalDate, setFinalDate] = React.useState(new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59));

  function renderRadio() {
    const handleChangeRadio = (event) => {
      setPeriod(event.target.value);
    };

    const handleInitialDateChange = (date) => {
      setInitialDate(date);
    };

    const handleFinalDateChange = (date) => {
      setFinalDate(date);
    };

    function renderPeriod() {
      return (
        period === 'all' ? <div /> :
          <div className={classes.containerPeriod}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant='inline'
                format='dd/MM/yyyy'
                margin='normal'
                label='Data Inicial'
                value={initialDate}
                onChange={handleInitialDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk
              />
              <Typography variant='subtitle1' className={classes.until}>até</Typography>
              <KeyboardDatePicker
                disableToolbar
                variant='inline'
                format='dd/MM/yyyy'
                margin='normal'
                label='Data Final'
                value={finalDate}
                onChange={handleFinalDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk
              />
            </MuiPickersUtilsProvider>
          </div>
      );
    }

    return (
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Escolha o período:</FormLabel>
        <RadioGroup row value={period} onChange={handleChangeRadio} className={classes.containerRadio}>
          <FormControlLabel value='all' control={<Radio />} label='Tudo' />
          <FormControlLabel value='perPeriod' control={<Radio />} label='Por Período' />
        </RadioGroup>
        {renderPeriod()}
      </FormControl>
    );
  }

  function renderEmail() {
    return (
      isSendEmail ?
        <DialogContentText>
          Envio para {data.name}, pelo e-mail: {data.address !== undefined ? data.address.email : ''}?
      </DialogContentText>
        : <div />
    );
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-labelledby='draggable-dialog-title'
      >
        <DialogTitle id='draggable-dialog-title'>
          Confirmação
        </DialogTitle>
        <DialogContent>
          {renderRadio()}
          {renderEmail()}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color='secondary'>Cancelar</Button>
          <Button onClick={() => handleConfirm(
            period, { initialDate: initialDate.toISOString(), finalDate: finalDate.toISOString() })} color='primary'>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SendEmailDialog;
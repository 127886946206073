import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import api from '../../services/api';
import { TOKEN_VALIDATED, LOGIN_MODE, SIGN_IN, LEND_BOOK_FETCHED } from '../../utils/types';
import consts from '../../utils/consts';

export function login(values, dispatch) {
  return submit(values, `${consts.API_URL}/signin`, true, dispatch);
}

export function signup(values, dispatch) {
  return submit(values, `${consts.API_URL}/signup`, false, dispatch);
}

function submit(values, url, isLogin, dispatch) {
  axios.post(url, values)
    .then(resp => {
      if (!isLogin) {
        alert('Usuário cadastrado com sucesso.');
        // toastr.success('Sucesso', 'Usuário cadastrado com sucesso.');
        dispatch({ type: LOGIN_MODE, payload: true });
      } else
        dispatch({ type: SIGN_IN, payload: resp.data });
    })
    .catch(err => {
      if (err.response === undefined)
        alert('Não foi possível conectar-se ao servidor, tente novamente mais tarde.');
      else if (err.response.status === 401)
        alert('E-mail ou senha estão inválidos, tente novamente.');
      // toastr.warning('E-mail ou senha estão inválidos, tente novamente.');
      else if (err.response.status === 400)
        alert(err.response.data);
      // toastr.warning(err.response);
      else
        alert('Erro no login, tente novamente.');
      // toastr.error('Erro no login, tente novamente.');
    });
}

export function validateToken(token, dispatch) {
  if (token) {
    api.post(`${consts.API_URL}/validateToken`, { token })
      .then(resp => {
        dispatch({ type: TOKEN_VALIDATED, payload: resp.data });
      })
      .catch(e => dispatch({ type: TOKEN_VALIDATED, payload: false }));
  } else {
    dispatch({ type: TOKEN_VALIDATED, payload: false });
  }
}

export function logout(dispatch) {
  dispatch({ type: TOKEN_VALIDATED, payload: false });
  dispatch({ type: LEND_BOOK_FETCHED, payload: { data: [] } });
}

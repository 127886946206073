import React, { useEffect, useRef, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, makeStyles } from '@material-ui/core';
import { useField } from '@unform/core';

const useStyles = makeStyles((theme) => ({
  colorMsg: {
    color: theme.palette.error.main
  },
}));

function CustomSelect({ label, name, options, defaultObj, ...props }) {
  const classes = useStyles();
  const selectRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [value, setValue] = useState(defaultValue ? defaultObj ? defaultValue._id : defaultValue : options[0]._id);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => ref.node.value
    })
  }, [fieldName, registerField]);

  // console.log(defaultValue);

  return (
    <FormControl {...props}>
      <InputLabel /*id={name}*/>{label}</InputLabel>
      <Select
        inputRef={selectRef}
        // labelId={name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        label={label}
        error={error ? true : false}
      // inputProps={{
      //     startAdornment: (
      //         <InputAdornment position='start'>
      //             <DoneIcon fontSize='small' />
      //         </InputAdornment>
      //     )
      // }}
      >
        {options.map(item => (
          <MenuItem key={item._id} value={item._id}>{item.value}</MenuItem>
        ))}
      </Select>
      {error && <FormHelperText className={classes.colorMsg}>{error}</FormHelperText>}
    </FormControl>
  );
}

export default CustomSelect;
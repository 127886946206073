import { CALENDAR_FETCHED, CALENDAR_INIT, CALENDAR_PRESENCE_FETCHED } from '../../utils/types';

const INITIAL_STATE = { list: null, calendar: {}, presences: null };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CALENDAR_FETCHED:
            return { ...state, list: action.payload.data.data };
        case CALENDAR_INIT:
            return { ...state, calendar: action.payload };
        case CALENDAR_PRESENCE_FETCHED:
            return { ...state, presences: action.payload.data.data };
        default:
            return state;
    }
};

import React, {useEffect, useRef, useState} from 'react';
import {
  makeStyles,
  CardContent,
  Button,
  Typography,
  Grid,
  CircularProgress,
  CardActions,
  Divider,
  IconButton,
} from '@material-ui/core';
import {
  CloudUpload,
   Close
} from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { init, create, update, remove } from '../../playlistActions';
import CustomTextField from '../../../../components/common/form/CustomTextField';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  container: {
    maxHeight: 440,
    padding: theme.spacing(1)
  },
  btn: {
    width: '10%',
    minWidth: '100px'
  },
  containerFile: {
    display: 'flex'
  },
  songs: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  songName: {
    flexGrow: '1'
  },
  cardContent: {
    paddingBottom: theme.spacing(3)
  },
  cardActions: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function List({ submitLabel }) {
  const classes = useStyles();
  const [utils, setUtils] = React.useState({
    showPassword: false,
    loading: false,
    disabled: false,
  });
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const playlist = useSelector(state => state.playlist.playlist);
  const { user } = useSelector(state => state.signIn);
  const [songs, setSongs] = useState([]);
  const [songsUploaded, setSongsUploaded] = useState([])

  async function handleSubmit(data) {
    try {
      const shema = Yup.object().shape({
        description: Yup.string().required('Descrição é obrigatório'),
      });

      await shema.validate(data, { abortEarly: false });

      setUtils({ ...utils, loading: true, disabled: true });
      // setTimeout(() => {
      //     console.log(data);
      //     setUtils({ ...utils, loading: false, disabled: false });
      // }, 3000);

      if (submitLabel === 'Incluir') create(data, songs, dispatch);
      else if (submitLabel === 'Alterar') {
        data._id = playlist._id;
        update({
          description: data.description,
          _id: playlist._id,
          songs: songsUploaded
        }, songs, dispatch);
      } else if (submitLabel === 'Excluir') await remove(data, dispatch);
      formRef.current.setErrors({});
    } catch (err) {
      console.log(err)
      if (err instanceof Yup.ValidationError) {
        const errorMessage = {};

        err.inner.forEach(error => {
          errorMessage[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessage);
      }
    }
    setUtils({ ...utils, loading: false, disabled: false });
  }

  useEffect(() => {
    if (playlist && playlist.songs) {
      setSongsUploaded(playlist.songs)
    }
  }, [playlist])

  function handleCancel() {
    init(dispatch, true);
  }

  function onChange({ target: { files } }) {
    setSongs([...songs, ...files])
  }

  if (user.userType === 'VENERAVEL' || user.userType === 'CHANCELER') {
    return (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={playlist}>
          <div className={classes.root}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                      className={classes.textField}
                      size='small'
                      fullWidth
                      label='Descrição*'
                      name='description'
                      type='text'
                      variant='outlined'
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={classes.containerFile}>
                    <input
                        color='primary'
                        accept='.mp3,audio/*'
                        type='file'
                        onChange={onChange}
                        id='icon-button-file'
                        style={{ display: 'none' }}
                        multiple={true}
                    />
                    <label htmlFor='icon-button-file'>
                      <Button
                          variant='outlined'
                          color='primary'
                          component='span'
                          className={classes.btnSearch}
                      >
                        Adicionar Música {' '}
                        <CloudUpload className={classes.extendedIcon} />
                      </Button>
                    </label>
                  </div>
                </Grid>
              </Grid>
              <Grid container direction={'column'} spacing={3}>
                {
                  (songsUploaded || []).map(song => (
                      <Grid item md={6} className={classes.songs}>
                        <div className={classes.songName}>
                          {song.filename}
                        </div>
                        <IconButton aria-label='edit' color='primary' size='small' onClick={() => {
                          const nextSongs = songsUploaded
                          nextSongs.splice(nextSongs.indexOf(song), 1)
                          setSongsUploaded([...nextSongs])
                        }}>
                          <Close />
                        </IconButton>;
                      </Grid>
                  ))
                }
                {
                  songs.map(song => (
                      <Grid item md={6} className={classes.songs}>
                        <div className={classes.songName}>
                          {song.name}
                        </div>
                        <IconButton aria-label='edit' color='primary' size='small' onClick={() => {
                          const nextSongs = songs
                          nextSongs.splice(nextSongs.indexOf(song), 1)
                          setSongs([...nextSongs])
                        }}>
                          <Close />
                        </IconButton>;
                      </Grid>
                  ))
                }
              </Grid>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardActions}>
              <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  className={classes.btn}
                  disabled={utils.disabled}
                  type='submit'
                  disabled={utils.disabled}
              >
                {utils.loading ?
                    <CircularProgress color='primary' size={24} /> :
                    <Typography variant='button' color='inherit'>{submitLabel}</Typography>}
              </Button>
              <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  className={classes.btn}
                  onClick={handleCancel}
              >
                Cancelar
              </Button>
            </CardActions>
          </div>
        </Form>
    );
  }

  return null
}

export default List;

import React, { useEffect } from 'react';
import { makeStyles, Typography, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { getPerson } from '..//MyFinancial/myFinancialActions';
import PeopleForm from '../People/components/PeopleForm';
import ContentHeader from '../../components/common/template/ContentHeader';
import { isEmpty } from '../../utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  containerTab: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  containerTotal: {
    display: 'flex'
  },
  total: {
    marginLeft: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  containerProgress: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

function MyRegister() {
  const classes = useStyles();
  const person = useSelector(state => state.myFinancial.person);
  const personComplete = useSelector(state => state.people.person);
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.signIn);

  useEffect(() => {
    getPerson(dispatch, user.id);
  }, []);

  const renderProgress = () => (
    <div className={classes.containerProgress}>
      <CircularProgress color='secondary' />
    </div>
  );

  return (
    <div className={classes.root}>
      <ContentHeader title='Cadastro' small='Meus Dados' />
      {/* <Typography variant='h5'>{person.name}</Typography> */}
      <div className={classes.containerTab}>
        {!isEmpty(personComplete) ?
          <PeopleForm submitLabel='Salvar' isUserDefault /> :
          renderProgress()
        }
      </div>
    </div>
  );
}

export default MyRegister;
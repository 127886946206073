import React from 'react';
import { makeStyles, CircularProgress, IconButton, Button } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import { isArrayEmpty, dateConvert, returnCorrectListName } from '../../../../utils/functions';
import { showUpdate, updateStatus, updateAmountBook } from '../../lendBookActions';
import { OPTIONS_TABLE, STATUS_BOOK_LIST } from '../../../../utils/consts';
import CustomDialog from '../../../../components/common/form/Dialog';

const useStyles = makeStyles((theme) => ({
  containerProgress: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  buttonDelivery: {
    backgroundColor: theme.palette.success.main,
    color: '#FFF',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
      color: '#FFF'
    }
  },
}));

const options = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'date',
    direction: 'asc'
  }
};
let position;
let statusSaved;

function List({ data }) {
  const classes = useStyles();
  const [utils, setUtils] = React.useState({
    item: {},
    open: false,
    openAccept: false
  });
  setStringTitle();

  function setStringTitle() {
    let newArray = [];
    for (let i = 0; i < data.length; i++) {
      data[i].lendItems.forEach(item => {
        newArray.push(item.book.title)
      });
      data[i]['lendItemsTitles'] = newArray;
      // data[i]['lendItemsTitles'] = newArray.join('\r\n');
      newArray = [];
    }
  }
  const columns = [
    {
      name: 'date',
      label: 'Data',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          dateConvert(value)
        )
      }
    },
    {
      name: 'person',
      label: 'Irmão',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value.name
        )
      }
    },
    {
      name: 'lendItemsTitles',
      label: 'Livro(os)',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            value.map(item =>
              <p>
                {item}
              </p>
            )
          )
        }
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (index) => {
          return (
            <td key={index}>
              {returnCorrectListName(data[index].status, STATUS_BOOK_LIST, 'value')}
            </td>
          );
        }
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const handleClick = () => {
            showUpdate(dispatch, data[dataIndex]);
          }
          const handleClickAccept = (status) => {
            status === STATUS_BOOK_LIST[1]._id ?
              setUtils({ ...utils, item: { '_id': data[dataIndex]._id }, open: true }) :
              setUtils({ ...utils, item: { '_id': data[dataIndex]._id }, openAccept: true });

            position = dataIndex;
            statusSaved = status;
          }

          return (
            <div>
              {data[dataIndex].status !== STATUS_BOOK_LIST[3]._id &&
                <IconButton aria-label='edit' color='primary' size='small' onClick={handleClick} className={classes.button}>
                  <EditIcon />
                </IconButton>
              }
              {data[dataIndex].status !== STATUS_BOOK_LIST[3]._id && data[dataIndex].status !== STATUS_BOOK_LIST[1]._id &&
                <Button
                  aria-label='confirm'
                  color='secondary'
                  size='small'
                  variant='contained'
                  onClick={() => handleClickAccept(data[dataIndex].status)}>
                  Autorizar
                  </Button>
              } {data[dataIndex].status !== STATUS_BOOK_LIST[3]._id && data[dataIndex].status === STATUS_BOOK_LIST[1]._id &&
                <Button
                  aria-label='delivery'
                  className={classes.buttonDelivery}
                  size='small'
                  variant='contained'
                  onClick={() => handleClickAccept(data[dataIndex].status)}>
                  Devolver
                  </Button>
              }
            </div>
          );
        }
      }
    },
  ];
  const dispatch = useDispatch();

  const handleConfirm = () => {
    const newArray = [...data];

    if (statusSaved === STATUS_BOOK_LIST[1]._id) { // delivery
      utils.item['status'] = STATUS_BOOK_LIST[3]._id
      newArray[position].status = STATUS_BOOK_LIST[3]._id;
      updateAmountBook(newArray[position], dispatch, newArray);
    } else { // accepted
      utils.item['status'] = STATUS_BOOK_LIST[1]._id
      newArray[position].status = STATUS_BOOK_LIST[1]._id;
      updateStatus(utils.item, dispatch, newArray);
    }
    handleClose();
  };

  const handleClose = () => {
    utils.open ?
      setUtils({ ...utils, open: false }) : setUtils({ ...utils, openAccept: false });
  };

  const renderProgress = () => (
    <div className={classes.containerProgress}>
      <CircularProgress color='secondary' />
    </div>
  );

  return (
    !isArrayEmpty(data) ?
      renderProgress() :
      <div>
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
        />
        <CustomDialog
          open={utils.openAccept}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          context='Deseja autorizar esse empréstimo?'
        />
        <CustomDialog
          open={utils.open}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          context='Deseja atualizar para devolvido esse empréstimo?'
        />
      </div>
  );
}

export default List;
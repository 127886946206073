import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import consts from '../../utils/consts';
import { initPeople } from '../People/peopleActions';
import { MY_FINANCIAL_FETCHED } from '../../utils/types';

export function getPerson(dispatch, id) {
  axios.get(`${consts.API_URL}/people/${id}`)
    .then(resp => {
      dispatch({ type: MY_FINANCIAL_FETCHED, payload: resp });
      initPeople(dispatch, resp.data);
    })
    .catch(e => console.log(e));
}

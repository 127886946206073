import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

import Header from '../../../../components/common/report/Header';
import { dateConvert, currencyFormat, returnCorrectListName } from '../../../../utils/functions';
import { SYSTEM_NAME, ACCOUNTS_GROUPS, PAYMENT_MODE, CARRIERS } from '../../../../utils/consts';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'colum',
    // backgroundColor: '#E4E4E4',
    padding: 15,
    // width: '100%',
    // height: '100%',
  },
  table: {
    // flexDirection: 'row',
    // justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 20,
  },
  headerTxt: {
    fontSize: 12,
  },
  containerTotal: {
    flexDirection: 'row',
    marginTop: 5,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  total: {
    fontSize: 16,
    marginLeft: 5
  },
  tableBody: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  bodyTxt: {
    fontSize: 9,
    // marginRight: 10
  },
  section: {
    // margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

const AccountsReport = ({ data, accountsMode }) => {

  const label = accountsMode === 0 ? 'Contas a Receber' : 'Contas a Pagar';
  const totalLabel = accountsMode === 0 ? 'Total a Receber: ' : 'Total a Pagar: ';

  function total() {
    const getTotal = item => item.value;
    const somar = (acc, el) => acc + el

    const totalGeral = data
      .map(getTotal)
      .reduce(somar);

    return currencyFormat(totalGeral);
  }

  return (
    <PDFViewer height={600} width={1200}>
      <Document
        author={SYSTEM_NAME}
        title='Caixa Loja'
      >
        <Page size="A4" style={styles.page}>
          <Header title={label} subtitle='Caixa Loja' link={SYSTEM_NAME} />
          <Table data={data}>
            <TableHeader>
              <TableCell isHeader>Emissão</TableCell>
              <TableCell isHeader>Valor</TableCell>
              <TableCell isHeader>Vencimento</TableCell>
              <TableCell isHeader>Grupo</TableCell>
              <TableCell isHeader>Histórico</TableCell>
              <TableCell isHeader>Modo Pagamento</TableCell>
              <TableCell isHeader>Portador</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell getContent={item => dateConvert(item.issuanceDate)} />
              <DataTableCell getContent={item => currencyFormat(item.value).toString()} />
              <DataTableCell getContent={item => dateConvert(item.dueDate)} />
              <DataTableCell getContent={item => returnCorrectListName(item.group, ACCOUNTS_GROUPS, 'value')} />
              <DataTableCell getContent={item => item.history} />
              <DataTableCell getContent={item => returnCorrectListName(item.paymentMode, PAYMENT_MODE, 'value')} />
              <DataTableCell getContent={item => returnCorrectListName(item.carrier, CARRIERS, 'value')} />
            </TableBody>
          </Table>
          <View style={styles.containerTotal}>
            <Text style={styles.headerTxt}>{totalLabel}</Text>
            <Text style={styles.total}>{total()}</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default AccountsReport;
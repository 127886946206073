import React, { useEffect } from 'react';
import { makeStyles, Paper, Tabs } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Add as AddIcon, List as ListIcon, Edit as EditIcon } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';

import { TAB_SELECTED } from '../../../src/utils/types';
import { init } from './lendBookActions';
import LendBookList from './tabs/LendBookList';
import LendBookMyList from './tabs/RequestBookList';
import LendBookForm from './tabs/LendBookForm';
import TabPanel from '../../components/common/Tab/components/TabPanel';
import TabHeader from '../../components/common/Tab/components/TabHeader';
import LabelAndIcon from '../../components/common/Tab/components/LabelAndIcon';
import ContentHeader from '../../components/common/template/ContentHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  container: {
    // padding: theme.spacing(1),
    // backgroundColor: theme.palette.background.paper,
  },
  containerTab: {
    backgroundColor: theme.palette.background.default,
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

function LendBook({ location }) {
  const classes = useStyles();
  const data = useSelector(state => state.lendBook.list);
  const dispatch = useDispatch();
  const theme = useTheme();
  const value = useSelector(state => state.tab.selected);
  const isRequestBook = location.state && location.state.isRequestBook;
  const { user } = useSelector(state => state.signIn);

  const setValue = (newValue) => {
    dispatch({ type: TAB_SELECTED, payload: newValue })
  }

  useEffect(() => {
    init(dispatch, true, isRequestBook, user.id);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <ContentHeader title={isRequestBook ? 'Solicitar Livros' : 'Empréstimo de Livros'} small='Movimentações' />
      <Paper square className={classes.container} color='default'>
        <div className={classes.containerTab}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons={window.innerWidth < 535 ? 'on' : 'auto'}
          >
            <TabHeader
              label={<LabelAndIcon icon={<ListIcon className={classes.icon} />} label='Listar' />}
              target='tabList'
            />
            <TabHeader
              label={<LabelAndIcon icon={<AddIcon className={classes.icon} />} label='Incluir' />}
              target='tabCreate'
            />
            <TabHeader
              label={<LabelAndIcon icon={<EditIcon className={classes.icon} />} label='Alterar' />}
              target='tabUpdate'
            />
            <TabHeader
              label={<LabelAndIcon icon={<ListIcon className={classes.icon} />} label='Minhas Solicitações' />}
              target='tabMyRequests'
            />
            <TabHeader
              label={<LabelAndIcon icon={<AddIcon className={classes.icon} />} label='Solicitar Livro' />}
              target='tabRequestBook'
            />
          </Tabs>
        </div>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <LendBookList data={data} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <LendBookForm submitLabel='Incluir' />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <LendBookForm submitLabel='Alterar' isRequestBook={isRequestBook} user={user} />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <LendBookMyList data={data} isRequestBook={isRequestBook} />
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <LendBookForm submitLabel='Solicitar' isRequestBook={isRequestBook} user={user} />
          </TabPanel>
        </SwipeableViews>
      </Paper>
    </div>
  );
}

export default LendBook;
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import consts from '../../utils/consts';
import { PEOPLE_FETCHED, BROTHER_BOX_INIT, BROTHER_BOX_FETCHED, PERSON_BOX_INIT, LOADING_PDF, SHOW_ERRORS_SEND_EMAILS } from '../../utils/types';

import { showTabs, selectTab } from '../../components/common/Tab/tabActions';

export function getList(dispatch) {
  axios.get(`${consts.API_URL}/people`)
    .then(resp => {
      dispatch({ type: PEOPLE_FETCHED, payload: resp });
    })
    .catch(e => console.log(e));
}

export function sendPdfEmail(dispatch, values) {
  // console.log(values);
  dispatch({ type: LOADING_PDF, payload: true });
  axios.post(`${consts.API_URL}/sendEmail`, values)
    .then(resp => {
      toastr.success('Sucesso', 'E-mail enviado com sucesso.');
      dispatch({ type: LOADING_PDF, payload: false });
    })
    .catch(e => {
      if (!e.response) {
        //console.log(e.response)
      } else {
        console.log(e)
        dispatch({ type: LOADING_PDF, payload: false });
        toastr.error('Erro', 'Falha ao realizar a operação.');
      }
    });
}

export function init(dispatch, reload) {
  dispatch([
    initBrotherBox(dispatch, {}),
    showTabs(dispatch, 'tabList', 'tabCreateForEveryone', 'tabSendForEveryone'),
    // showTabs(dispatch, 'tabList'),
    selectTab(dispatch, 0),
    reload && getList(dispatch),
    showBrotherBox(dispatch, false)
  ]);
}

export function showBrotherList(dispatch, person) {
  if (person.brotherBox.length > 0) {
    sortListByDate(person.brotherBox);
    setBalance(person.brotherBox);
  }
  dispatch([
    initBrotherBox(dispatch, {}),
    showTabs(dispatch, 'tabListBorther', 'tabCreate'),
    selectTab(dispatch, 1),
    initPerson(dispatch, person),
    showBrotherBox(dispatch, true),
  ]);
}

export function showUpdate(dispatch, brotherBox) {
  dispatch([
    showTabs(dispatch, 'tabUpdate'),
    selectTab(dispatch, 3),
    initBrotherBox(dispatch, brotherBox)
  ]);
}

export function removeItem(dispatch, brotherBox) {
  dispatch([
    showTabs(dispatch, 'tabUpdate'),
    selectTab(dispatch, 3),
    initBrotherBox(dispatch, brotherBox)
  ]);
}

function initBrotherBox(dispatch, value) {
  dispatch({ type: BROTHER_BOX_INIT, payload: value });
}

function initPerson(dispatch, value) {
  dispatch({ type: PERSON_BOX_INIT, payload: value });
}

function showBrotherBox(dispatch, value) {
  dispatch({ type: BROTHER_BOX_FETCHED, payload: value });
}

export function sortListByDate(data) {
  return data.sort((a, b) => new Date(a.date) - new Date(b.date));
}

export function setBalance(data) {
  const newArray = [];
  data.forEach((item, i) => {
    let prev = i > 0 ? data[i - 1] : null;

    item['balance'] = prev === null ? (item.credit - item.debt) :
      (item.credit - item.debt) + prev.balance;
    newArray.push(item);
  });
  return newArray;
}

export function updateBrotherBox(values, dispatch) {
  axios.post(`${consts.API_URL}/brotherBox`, values)
    .then(resp => {
      toastr.success('Sucesso', 'Operação Realizada com sucesso.');
      init(dispatch, true);
    })
    .catch(e => {
      toastr.error('Erro', e.response.data !== undefined ? e.response.data : e.response);
    });
}

export function updatePersonFromList(dispatch, person, list) {
  let index = 0;
  const newList = list.filter((item, i) => {
    index = item._id === person._id ? i : 0;
    return item._id !== person._id;
  });
  newList.splice(index, 0, person);
  dispatch({ type: PEOPLE_FETCHED, payload: { data: { data: newList } } });
}

export function sendEmailForAll(values, dispatch, callback) {
  axios.post(`${consts.API_URL}/sendEmailToAll`, values)
    .then(resp => {
      if (resp.data.errors.length === 0)
        toastr.success('Sucesso', 'Operação Realizada com sucesso.');
      else
        toastr.warning('Alerta', 'Alguns e-mails com problemas.');

      callback();
      dispatch({ type: SHOW_ERRORS_SEND_EMAILS, payload: resp.data.errors });
    })
    .catch(e => {
      toastr.error('Erro', e.response.data !== undefined ? e.response.data : e.response);
    });
}

export function setAccount(dispatch, values) {
  axios.post(`${consts.API_URL}/accountReceivable`, values)
    .then(resp => {
      // console.log(resp);
    })
    .catch(e => console.log(e));
}
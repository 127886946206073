import { PROVIDER_FETCHED, PROVIDER_INIT } from '../../utils/types';

const INITIAL_STATE = { list: [], provider: {} };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROVIDER_FETCHED:
            return { ...state, list: action.payload.data.data };
        case PROVIDER_INIT:
            return { ...state, provider: action.payload };
        default:
            return state;
    }
};

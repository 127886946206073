import React from 'react';
import { makeStyles, TableRow, TableCell, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import { isArrayEmpty, currencyFormat, existsContent } from '../../../../utils/functions';
import { showBrotherList, setBalance, sortListByDate } from '../../brotherBoxActions';
import { OPTIONS_TABLE } from '../../../../utils/consts';

const useStyles = makeStyles((theme) => ({
  containerProgress: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const options = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'name',
    direction: 'asc'
  },
  rowsPerPage: 100
};

function List({ data }) {
  const classes = useStyles();
  const columns = [
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'level',
      label: 'Grau',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'cpfCnpj',
      label: 'CPF/CNPJ',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'brotherBox',
      label: 'Saldo',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let isPositive = true;
          if (existsContent(value)) {
            const items = setBalance(sortListByDate(value));
            const item = items.pop();
            isPositive = item.balance < 0 ? false : true;
            return (
              <Typography variant='body1' color={isPositive ? 'secondary' : 'error'}>
                {currencyFormat(item.balance)}
              </Typography>
            );
          } else
            return (
              <Typography variant='body1' color={isPositive ? 'secondary' : 'error'}>R$ 0,00</Typography>
            );
        }
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const handleClick = () => showBrotherList(dispatch, data[dataIndex]);

          return (
            <IconButton aria-label='edit' color='primary' size='small' onClick={handleClick}>
              <EditIcon />
            </IconButton>
          );
        }
      }
    },
  ];

  const dispatch = useDispatch();

  const renderProgress = () => (
    <div className={classes.containerProgress}>
      <CircularProgress color='secondary' />
    </div>
  );

  return (
    !isArrayEmpty(data) ?
      renderProgress() :
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
      />
  );
}

export default List;

import React from 'react';
import { makeStyles, CircularProgress, IconButton } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import { OPTIONS_TABLE, ACCOUNTS_GROUPS, PAYMENT_MODE, CARRIERS } from '../../../../utils/consts';
import { dateConvert, currencyFormat, isArrayEmpty, returnCorrectListName } from '../../../../utils/functions';
import { showUpdate, remove } from '../../accountsActions';
import CustomDialog from '../../../../components/common/form/Dialog';

const useStyles = makeStyles((theme) => ({
  containerProgress: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  containerBtn: {
    display: 'flex'
  },
  icon: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main,
  },
}));

const options = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'issuanceDate',
    direction: 'asc'
  },
  rowsPerPage: 100
};

function AReceivableList({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState({});
  
  const columns = [
    {
      name: 'issuanceDate',
      label: 'Emissão',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (index) => {
          return (
            <td key={index}>
              {dateConvert(data[index].issuanceDate)}
            </td>
          );
        }
      }
    },
    {
      name: 'dueDate',
      label: 'Vencimento',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (index) => {
          return (
            <td key={index}>
              {dateConvert(data[index].dueDate)}
            </td>
          );
        }
      }
    },
    {
      name: 'group',
      label: 'Grupo',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (index) => {
          return (
            <td key={index}>
              {returnCorrectListName(data[index].group, ACCOUNTS_GROUPS, 'value')}
            </td>
          );
        }
      }
    },
    {
      name: 'history',
      label: 'Histórico',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'paymentMode',
      label: 'Modo de Pagamento',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (index) => {
          return (
            <td key={index}>
              {returnCorrectListName(data[index].paymentMode, PAYMENT_MODE, 'value')}
            </td>
          );
        }
      }
    },
    {
      name: 'carrier',
      label: 'Portador',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (index) => {
          return (
            <td key={index}>
              {returnCorrectListName(data[index].carrier, CARRIERS, 'value')}
            </td>
          );
        }
      }
    },
    {
      name: 'value',
      label: 'Valor',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (index) => {
          return (
            <td key={index}>
              {currencyFormat(data[index].value)}
            </td>
          );
        }
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const handleClick = () => showUpdate(dispatch, data[dataIndex], true);

          return (
            <div className={classes.containerBtn}>
              {/* <IconButton aria-label='edit' color='primary' size='small' onClick={handleClick}>
                <EditIcon />
              </IconButton> */}
              <IconButton aria-label='delete' color='secondary' size='small' onClick={() => handleClickOpen(data[dataIndex])} className={classes.icon}>
                <DeleteIcon />
              </IconButton>
            </div>
          );
        }
      }
    },
  ];

  const handleClickOpen = (aReceivable) => {
    setOpen(true);
    setItem(aReceivable);
  };

  const handleConfirm = () => {
    remove(item, dispatch, 'aReceivable');
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderProgress = () => (
    <div className={classes.containerProgress}>
      <CircularProgress color='secondary' />
    </div>
  );

  return (
    !isArrayEmpty(data) ?
      renderProgress() :
      <div>
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
        />
        <CustomDialog open={open} handleClose={handleClose} handleConfirm={handleConfirm} context='Deseja realmente excluir esse item?' />
      </div>
  );
}

export default AReceivableList;
import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 36,
    alignItems: 'center',
    // backgroundColor: '#CCC'
  },
  title: {
    marginRight: theme.spacing(1),
  }
}));

function ContentHeader({ title, small }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant='h5' className={classes.title}>{title}</Typography>
      <Typography variant='body2'>{small}</Typography>
    </div>
  );
}

export default ContentHeader;
import api from '../../services/api';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import consts from '../../utils/consts';
import { PLAYLIST_FETCHED, PLAYLIST_INIT,  } from '../../utils/types';

import { showTabs, selectTab } from '../../components/common/Tab/tabActions';

export function getList(dispatch, userLevel, isAdm) {
  axios({
    method: 'get',
    url: `${consts.API_URL}/playlists`,
    params: {
      level: isAdm ? userLevel : null
    }
  })
    .then(resp => {
      dispatch({ type: PLAYLIST_FETCHED, payload: resp });
    })
    .catch(e => console.log(e));
}

export function create(values, files, dispatch) {
  return submit(values, files,'post', dispatch);
}

export function update(values, files, dispatch) {
  return submit(values, files, 'put', dispatch);
}

export function remove(values, dispatch) {
  const id = values._id ? values._id : '';
  return axios.delete(`${consts.API_URL}/playlists/${id}`, values)
      .then(resp => {
        toastr.success('Sucesso', 'Operação Realizada com sucesso.');
        init(dispatch, true);
      })
      .catch(e => {
        toastr.error('Erro', e.response.data);
      });
}

function submit(values, files, method, dispatch) {
  const id = values._id ? values._id : '';
  var formData = new FormData();
  Object.keys(values).forEach(key => {
    if (key === 'songs') {
      formData.append(key, JSON.stringify(values[key]));
    } else formData.append(key, values[key]);
  })
  files.forEach(file => {
    formData.append('songsFile', file)
  })
  axios[method](`${consts.API_URL}/playlists/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(resp => {
      toastr.success('Sucesso', 'Operação Realizada com sucesso.');
      init(dispatch, true);
    })
    .catch(e => {
      toastr.error('Erro', e.response.data);
    });
}

export function init(dispatch, reload) {
  dispatch([
    initPlaylist(dispatch, {}),
    showTabs(dispatch, 'tabList', 'tabCreate'),
    selectTab(dispatch, 0),
    reload && getList(dispatch)
  ]);
}

export function showUpdate(dispatch, playlist) {
  dispatch([
    showTabs(dispatch, 'tabUpdate'),
    selectTab(dispatch, 2),
    initPlaylist(dispatch, playlist)
  ]);
}

export function showPlayer(dispatch, playlist) {
  dispatch([
    showTabs(dispatch, 'tabPlayer'),
    selectTab(dispatch, 3),
    initPlaylist(dispatch, playlist)
  ]);
}

export function stopPlayer(dispatch) {
  dispatch({ type: PLAYLIST_INIT, payload: null });
}


function initPlaylist(dispatch, value) {
  dispatch({ type: PLAYLIST_INIT, payload: value });
}

import React from 'react';
import { makeStyles, CircularProgress, IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import { showUpdate } from '../../lendBookActions';
import { dateConvert, isArrayEmpty, returnCorrectListName } from '../../../../utils/functions';
import { OPTIONS_TABLE, STATUS_BOOK_LIST } from '../../../../utils/consts';

const useStyles = makeStyles((theme) => ({
  containerProgress: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const options = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'date',
    direction: 'asc'
  }
};

function List({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const columns = [
    {
      name: 'date',
      label: 'Data',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          dateConvert(value)
        )
      }
    },
    {
      name: 'person',
      label: 'Irmão',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value.name
        )
      }
    },
    {
      name: 'lendItems',
      label: 'Qtd itens',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value.length
        )
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (index) => {
          return (
            <td key={index}>
              {returnCorrectListName(data[index].status, STATUS_BOOK_LIST, 'value')}
            </td>
          );
        }
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const handleClick = () => {
            showUpdate(dispatch, data[dataIndex]);
          }

          return (
            data[dataIndex].status !== STATUS_BOOK_LIST[1]._id && data[dataIndex].status !== STATUS_BOOK_LIST[3]._id &&
              <IconButton aria-label='edit' color='primary' size='small' onClick={handleClick}>
                <EditIcon />
              </IconButton>
          );
        }
      }
    },
  ];

  const renderProgress = () => (
    <div className={classes.containerProgress}>
      <CircularProgress color='secondary' />
    </div>
  );

  return (
    !isArrayEmpty(data) ?
      renderProgress() :
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
      />
  );
}

export default List;
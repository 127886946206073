import { TAB_SELECTED, TAB_SHOWED } from '../../../utils/types';

const INITIAL_STATE = { selected: 0, visible: {} };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TAB_SELECTED:
            return { ...state, selected: action.payload };
        case TAB_SHOWED:
            return { ...state, visible: action.payload };
        default:
            return state;
    }
};

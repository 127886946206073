import React, { useRef } from 'react';
import {
  makeStyles, CardContent, Button, Typography, Grid, CircularProgress, CardActions, Divider
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { replaceValue, isEmpty, isArrayEmpty, existsContent } from '../../../../utils/functions';
import { showBrotherList, updateBrotherBox, init, updatePersonFromList, setAccount } from '../../brotherBoxActions';
import { update } from '../../../People/peopleActions';
import { GROUPS, ACCOUNTS_GROUPS, PAYMENT_MODE, CARRIERS, BROTHER_SELECTED, STATUS_BROTHER_BOX_ACCOUNT } from '../../../../utils/consts';
import CustomTextField from '../../../../components/common/form/CustomTextField';
import CustomSelect from '../../../../components/common/form/CustomSelect';
import CustomDialog from '../../../../components/common/form/Dialog';
import CustomAutoComplete from '../../../../components/common/form/CustomAutoComplete';
import CustomCheckbox from '../../../../components/common/form/CustomCheckbox';
import CustomDatePicker from '../../../../components/common/form/CustomDatePicker';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  btn: {
    width: '10%',
    minWidth: '100px'
  },
  cardContent: {
    paddingBottom: theme.spacing(3)
  },
  cardActions: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  containerTab: {
    backgroundColor: theme.palette.background.default,
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

function BrotherBoxForm({ submitLabel }) {
  const classes = useStyles();
  const [utils, setUtils] = React.useState({
    showPassword: false,
    loading: false,
    disabled: false
  });
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const person = useSelector(state => state.brotherBox.person);
  const personList = useSelector(state => state.brotherBox.list);
  const brotherBox = useSelector(state => state.brotherBox.brotherBox);
  const tab = useSelector(state => state.tab.selected);
  const [open, setOpen] = React.useState(false);
  const [temporary, setTemporary] = React.useState({});

  const handleConfirm = () => {
    updateBrotherBox(temporary, dispatch);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleCancel() {
    cancelAction(dispatch, person, true);
  }

  function cancelAction(dispatch, person, updatePeople) {
    if (tab === 4)
      init(dispatch, false)
    else {
      showBrotherList(dispatch, person);
      !updatePeople && updatePersonFromList(dispatch, person, personList);
    }
  }

  async function handleSubmit(data) {
    function checkAccount(list, item) {
      const balance = list.pop().balance;
      const isCredit = existsContent(item.credit);
      const value = item.debt !== '' ? replaceValue(item.debt) : replaceValue(item.credit);
      const newBalance = balance - Number.parseInt(value);
      
      if (newBalance > 0 || isCredit) {
        item.issuanceDate = item.date;
        item.dueDate = item.date;
        item.group = ACCOUNTS_GROUPS.filter(item => item._id === 'CAIXA DOS IRMAOS')[0]._id;
        item.history = ACCOUNTS_GROUPS.filter(item => item._id === 'CAIXA DOS IRMAOS')[0].value;
        item.paymentMode = PAYMENT_MODE.filter(item => item._id === 'OUTROS')[0]._id;
        item.carrier = CARRIERS.filter(item => item._id === 'CAIXA LOJA')[0]._id;
        item.clientBrother = BROTHER_SELECTED._id;
        item.status = STATUS_BROTHER_BOX_ACCOUNT._id;
        item.value = Number.parseInt(value);
        setAccount(dispatch, item);
      }
    }
    try {
      const shema = Yup.object().shape({
        date: Yup.string().required('A data é obrigatória'),
        history: Yup.string().required('O histórico é obrigatório'),
        group: Yup.string().required('O grupo é obrigatório').notOneOf([GROUPS[0].value], 'O grupo é obrigatório'),
      });

      await shema.validate(data, { abortEarly: false });

      setUtils({ ...utils, loading: true, disabled: true });

      data.debt = replaceValue(data.debt);
      data.credit = replaceValue(data.credit);

      if (submitLabel === 'Alterar') data._id = brotherBox._id

      if (tab === 4) {
        data.forAll = true;
        setTemporary(data);
        setOpen(true);
      } else {
        person.brotherBox.forEach(item => {
          if (item._id === brotherBox._id) {
            if (item.hasOwnProperty('forAll') && item.forAll)
              data.forAll = true;
          }
        });
        const anotherBrother = person.brotherBox.filter(item => item._id !== brotherBox._id);
        if (data.payment) {
          checkAccount([...anotherBrother], { ...data });
        }
        anotherBrother.push(data);
        const newPerson = { ...person, brotherBox: anotherBrother }

        update(newPerson, dispatch, cancelAction);
      }
      formRef.current.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage = {};

        err.inner.forEach(error => {
          errorMessage[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessage);
      }
    }
    setUtils({ ...utils, loading: false, disabled: false });
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={brotherBox}>
      <div className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <CustomDatePicker
                className={classes.textField}
                size='small'
                fullWidth
                label='Data'
                name='date'
                type='text'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Débito'
                name='debt'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Crédito'
                name='credit'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomSelect
                name='group'
                variant='outlined'
                className={classes.formControl}
                label='Grupo'
                fullWidth
                size='small'
                options={GROUPS}
              />
            </Grid>
            <Grid item md={5} xs={12}>
              {tab === 3 ?
                <CustomTextField
                  className={classes.textField}
                  size='small'
                  fullWidth
                  label='Histórico'
                  name='history'
                  type='text'
                  variant='outlined'
                />
                :
                <CustomAutoComplete
                  className={classes.textField}
                  size='small'
                  fullWidth
                  label='Histórico'
                  name='history'
                  type='text'
                  variant='outlined'
                  data={isEmpty(person) ? isArrayEmpty(personList) ? personList[0].brotherBox : [] : person.brotherBox}
                />
              }
            </Grid>
            <Grid item md={4} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Observação'
                name='observation'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              {tab === 4 ? <CustomCheckbox name='meritorious' label='Lançar para eméritos?' checkedDefault /> :
                <CustomCheckbox name='payment' label='Pagamento para Loja' />
              }
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className={classes.btn}
            disabled={utils.disabled}
            type='submit'
          >
            {utils.loading ?
              <CircularProgress color='primary' size={24} /> :
              <Typography variant='button' color='inherit'>{submitLabel}</Typography>}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            className={classes.btn}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </CardActions>
        <CustomDialog open={open} handleClose={handleClose} handleConfirm={handleConfirm} context='Deseja realmente incluir este lançamento para todos os irmãos?' />
      </div>
    </Form>
  );
}

export default BrotherBoxForm;
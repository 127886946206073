import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { validateToken } from './pages/SignIn/signInActions';
import App from './App';
import AuthRoutes from './routes/auth.routes';

function AuthOrApp() {
  const auth = useSelector(state => state.signIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.user)
      validateToken(auth.user.token, dispatch);
  }, []);

  const { user, validToken } = auth;
  if (user && validToken) {
    axios.defaults.headers.common['authorization'] = `bearer ${user.token}`;
    return <App />
  } else if (!user && !validToken) {
    return <AuthRoutes />
  } else {
    return false;
  }
}

export default AuthOrApp;
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { FormControlLabel, Checkbox } from '@material-ui/core';

function CustomCheckbox({ name, label, checkedDefault, ...props }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  const [checked, setChecked] = React.useState(defaultValue || checkedDefault ? true : false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return (ref.value === 'true');
      }
    })
  }, [fieldName, registerField]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          inputRef={inputRef}
          checked={checked}
          onChange={handleChange}
          value={checked}
          {...props}
          name='checked'
          color='primary'
        />
      }
      label={label}
    />
  );
}

export default CustomCheckbox;
import React from 'react';
import { makeStyles, CircularProgress, IconButton } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import { isArrayEmpty } from '../../../../utils/functions';
import { showUpdate } from '../../bookActions';
import { OPTIONS_TABLE } from '../../../../utils/consts';

const useStyles = makeStyles((theme) => ({
  containerProgress: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const options = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'title',
    direction: 'asc'
  }
};

function List({ data }) {
  const classes = useStyles();
  const columns = [
    {
      name: 'title',
      label: 'Título',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'description',
      label: 'Descrição',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'level',
      label: 'Grau',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'amount',
      label: 'Estoque',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        // customBodyRender: (value, tableMeta, updateValue) => {
        customBodyRenderLite: (dataIndex) => {
          const handleClick = () => {
            showUpdate(dispatch, data[dataIndex]);
          }
          // const handleClick = () => showBrotherList(dispatch, data[dataIndex]);

          return (
            <IconButton aria-label='edit' color='primary' size='small' onClick={handleClick}>
              <EditIcon />
            </IconButton>
          );
        }
      }
    },
  ];

  const dispatch = useDispatch();

  const renderProgress = () => (
    <div className={classes.containerProgress}>
      <CircularProgress color='secondary' />
    </div>
  );

  return (
    !isArrayEmpty(data) ?
      renderProgress() :
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
      />
  );
}

export default List;
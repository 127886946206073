import React, { useRef } from 'react';
import {
  makeStyles, CardContent, Button, Typography, Grid, InputAdornment, CircularProgress, CardActions, Divider,
  Paper, Tabs, Tab
} from '@material-ui/core';
import {
  Book as BookIcon, Description as DescriptionIcon, Home as HomeIcon,
  AccountCircle as AccountIcon, SupervisedUserCircle as SupervisedIcon
} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { cpf, cnpj } from 'cpf-cnpj-validator';

import { init, create, update, remove, initPeople } from '../../peopleActions';
import { LEVEL_PERSON_LIST, MARITAL_STATE } from '../../../../utils/consts';
import CustomTextField from '../../../../components/common/form/CustomTextField';
import CustomDatePicker from '../../../../components/common/form/CustomDatePicker';
import CustomSelect from '../../../../components/common/form/CustomSelect';
import CustomCheckbox from '../../../../components/common/form/CustomCheckbox';
import TabPanel from '../../../../components/common/Tab/components/TabPanel';
import LabelAndIcon from '../../../../components/common/Tab/components/LabelAndIcon';
import Address from './Address';
import Spouse from './Spouse';
import AccessData from './AccessData';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  btn: {
    width: '10%',
    minWidth: '100px'
  },
  cardContent: {
    paddingBottom: theme.spacing(3)
  },
  cardActions: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  containerTab: {
    backgroundColor: theme.palette.background.default,
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

let temporary = {};

function List({ submitLabel, isUserDefault = false }) {
  const classes = useStyles();
  const [utils, setUtils] = React.useState({
    showPassword: false,
    loading: false,
    disabled: false,
  });
  const theme = useTheme();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const person = useSelector(state => state.people.person);
  console.log(person)
  const [tabSelected, setTab] = React.useState(0);
  const isNew = initializeIfIsNew(person.accessData);
  // console.log(person);
  if (person.accessData !== undefined && person.accessData.password !== undefined) {
    temporary = { ...person.accessData };
    delete person.accessData.password;
  }

  function initializeIfIsNew(data) {
    if (data !== undefined)
      return Object.keys(data).length > 1 ? false : true;
    else
      return false
  }

  function handleCancel() {
    init(dispatch, true);
  }

  const handleChange = (event, newValue) => {
    const allData = formRef.current.getData();

    if (!allData.hasOwnProperty('accessData'))
      allData.accessData = person.accessData;
    if (!allData.hasOwnProperty('address'))
      allData.address = person.address;
    if (!allData.hasOwnProperty('spouse'))
      allData.spouse = person.spouse;
    if (!allData.hasOwnProperty('_id'))
      allData._id = person._id;

    initPeople(dispatch, allData);
    setTab(newValue);
  }

  const handleChangeIndex = (index) => {
    setTab(index);
  };

  async function handleSubmit(data) {
    if (data.address === undefined) data.address = person.address;
    if (data.spouse === undefined) data.spouse = person.spouse;
    if (data.accessData === undefined) data.accessData = temporary;

    // console.log(data);
    try {
      const shema = Yup.object().shape({
        name: Yup.string().min(6, 'O nome deve ter pelo menos 6 caracteres').required('O nome é obrigatório'),
        cpfCnpj: Yup.string().test('isCpf', data.cpfCnpj.length > 14 ? 'CNPJ inválido!' : 'CPF inválido',
          value => data.cpfCnpj.length > 14 ? cnpj.isValid(value) : cpf.isValid(value))
          .required('O CPF/CNPJ é obrigatório'),
        address: Yup.object().shape({ email: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório') }),
        level: Yup.string().required('O grau é obrigatório').notOneOf([LEVEL_PERSON_LIST[0].value], 'O grau é obrigatório'),
        // accessData: data.accessData.email !== '' || data.accessData.password !== '' ?
        accessData: data.accessData.hasOwnProperty('email') && data.accessData.email !== '' ||
          data.accessData.hasOwnProperty('newPassword') && data.accessData.newPassword !== '' ?
          Yup.object().shape({
            email: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório'),
            // password: isNew ?
            //   Yup.string().min(6, 'A senha deve conter pelo menos 6 caracteres').required('A senha é obrigatória') : null,
            confirmPassword: isNew ?
              Yup.string().oneOf([Yup.ref('newPassword'), null], 'Senhas diferentes').required('A confirmação de senha é obrigatória') : null,
            newPassword: isNew || data.accessData.newPassword !== '' ?
              Yup.string().min(6, 'A nova senha deve conter pelo menos 6 caracteres') : null,
            newConfirmPassword: data.accessData.newPassword !== '' ?
              Yup.string().oneOf([Yup.ref('newPassword'), null], 'Senhas diferentes') : null,
            // currentPassword: !isNew && Yup.string().required('A senha atual é obrigatória')
          }) : null
      });

      await shema.validate(data, { abortEarly: false });

      delete data.accessData.confirmPassword;
      delete data.accessData.newConfirmPassword;

      if (data.accessData.newPassword === '') {
        delete data.accessData.newPassword;
        data.accessData.password = temporary.password;
      }
      if (isUserDefault)
        data.accessData.userType = temporary.userType;

      // console.log(data);
      setUtils({ ...utils, loading: true, disabled: true });

      if (submitLabel === 'Incluir') create(data, dispatch);
      else if (submitLabel === 'Alterar' || submitLabel === 'Salvar') {
        data._id = person._id;
        update(data, dispatch);
      } else if (submitLabel === 'Excluir') remove(data, dispatch);

      formRef.current.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage = {};
        err.inner.forEach(error => {
          errorMessage[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessage);
      }
    }
    setUtils({ ...utils, loading: false, disabled: false });
  }

  const renderTabs = (person) => (
    <Paper square className={classes.container} color='default'>
      <div className={classes.containerTab}>
        <Tabs
          value={tabSelected}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons={window.innerWidth < 535 ? 'on' : 'auto'}
        >
          <Tab
            label={<LabelAndIcon icon={<HomeIcon className={classes.icon} />} label='Residência' />}
            target='tabAddress'
          />
          <Tab
            label={<LabelAndIcon icon={<SupervisedIcon className={classes.icon} />} label='Cônjuge' />}
            target='tabSpouse'
          />
          <Tab
            label={<LabelAndIcon icon={<AccountIcon className={classes.icon} />} label='Dados Acesso' />}
            target='tabAccess'
          />
        </Tabs>
      </div>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabSelected}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={tabSelected} index={0} dir={theme.direction}>
          <Address />
        </TabPanel>
        <TabPanel value={tabSelected} index={1} dir={theme.direction}>
          <Spouse />
        </TabPanel>
        <TabPanel value={tabSelected} index={2} dir={theme.direction}>
          <AccessData isNew={isNew} isUserDefault={isUserDefault} />
        </TabPanel>
      </SwipeableViews>
    </Paper>
  )

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={person}>
      <div className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Nome*'
                name='name'
                type='text'
                variant='outlined'
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position='start'>
              //       <BookIcon fontSize='small' />
              //     </InputAdornment>
              //   ),
              // }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='CPF/CNPJ*'
                name='cpfCnpj'
                type='text'
                variant='outlined'
                mask='cpfCnpj'
                fieldType='mask'
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position='start'>
              //       <DescriptionIcon fontSize='small' />
              //     </InputAdornment>
              //   )
              // }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Cadastro (CIM)'
                name='registerCIM'
                type='text'
                variant='outlined'
                disabled={isUserDefault}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomDatePicker
                className={classes.textField}
                size='small'
                fullWidth
                label='Data de Nascimento'
                name='dateOfBirth'
                type='text'
                isBirthday
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomSelect
                name='maritalStatus'
                variant='outlined'
                className={classes.formControl}
                label='Estado Civil'
                fullWidth
                size='small'
                options={MARITAL_STATE}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Profissão'
                name='profession'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Tipo Sanguíneo'
                name='bloodType'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Cargo em Loja'
                name='postInStore'
                type='text'
                variant='outlined'
                disabled={isUserDefault}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomSelect
                name='level'
                variant='outlined'
                className={classes.formControl}
                label='Grau*'
                fullWidth
                size='small'
                options={LEVEL_PERSON_LIST}
                disabled={isUserDefault}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Pecúlio'
                name='estate'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomCheckbox name='votingRight' label='Direito a Voto' disabled={isUserDefault} />
              <CustomCheckbox name='meritorious' label='Emérito' disabled={isUserDefault} />
            </Grid>
          </Grid>
          {renderTabs(person)}
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className={classes.btn}
            disabled={utils.disabled}
            type='submit'
          >
            {utils.loading ?
              <CircularProgress color='primary' size={24} /> :
              <Typography variant='button' color='inherit'>{submitLabel}</Typography>}
          </Button>
          {!isUserDefault &&
            <Button
              variant='contained'
              color='secondary'
              size='small'
              className={classes.btn}
              onClick={handleCancel}
            >
              Cancelar
          </Button>
          }
        </CardActions>
      </div>
    </Form>
  );
}

export default List;

import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

function CustomDatePicker({ label, name, isBirthday, ...props }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  let { defaultValue } = useField(name);
  let initValue;
  if (defaultValue === 'Invalid date' || defaultValue === undefined)
    initValue = null;
  else initValue = defaultValue;
  const [selectedDate, handleDateChange] = React.useState(initValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        if (ref.value !== '') {
          const dateNow = new Date();
          
          const date = moment(ref.value, ['DD/MM/YYYY', 'MM/DD/YYYY']).toDate();
          date.setHours(dateNow.getHours());
          date.setMinutes(dateNow.getMinutes());
          date.setSeconds(dateNow.getSeconds());
          
          const newDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
          
          return newDate;
        } else return '';
      }
    })
  }, [fieldName, registerField]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {isBirthday ?
        <KeyboardDatePicker
          {...props}
          inputRef={inputRef}
          disableToolbar
          inputVariant='outlined'
          format='dd/MM/yyyy'
          margin='normal'
          disableFuture
          openTo='year'
          views={['year', 'month', 'date']}
          value={selectedDate}
          onChange={handleDateChange}
          name={name}
          label={label}
          style={{ marginTop: 0 }}
          // variant='inline'
          autoOk
          error={error ? true : false}
          helperText={error}
          defaultValue={defaultValue}
        /> :
        <KeyboardDatePicker
          {...props}
          inputRef={inputRef}
          disableToolbar
          inputVariant='outlined'
          format='dd/MM/yyyy'
          margin='normal'
          value={selectedDate}
          onChange={handleDateChange}
          name={name}
          label={label}
          style={{ marginTop: 0 }}
          // variant='inline'
          autoOk
          error={error ? true : false}
          helperText={error}
          defaultValue={defaultValue}
        />
      }
    </MuiPickersUtilsProvider>
  );
}

export default CustomDatePicker;
import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, makeStyles, Hidden } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import LogoIcon from '@material-ui/icons/AccountBalance';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CustomDialog from '../../../../components/common/form/Dialog';
import { logout } from '../../../../pages/SignIn/signInActions';
import { SYSTEM_NAME } from '../../../../utils/consts';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  defaultLogo: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  title: {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center'
  },
}));

function Topbar(props) {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout(dispatch);
    setOpen(false);
    history.push('/sign-in');
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to='/' className={classes.defaultLogo}>
          <Typography variant='h4' className={classes.title}>
            <LogoIcon color='inherit' style={{ marginRight: 6 }} />{SYSTEM_NAME}
          </Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton
            className={classes.signOutButton}
            color='inherit'
            onClick={handleClickOpen}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color='inherit'
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <CustomDialog open={open} handleClose={handleClose} handleConfirm={handleLogout} context='Deseja realmente sair do sistema?' />
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;

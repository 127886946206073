import React, { useEffect, useRef } from 'react';
import { TextField } from '@material-ui/core';
import { useField } from '@unform/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function CustomAutoComplete({ label, name, type, data, className }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  let { defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => ref.value
    })
  }, [fieldName, registerField]);

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={data.map((option) => option.history)}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          label={label}
          name={name}
          className={className}
          size='small'
          fullWidth
          variant='outlined'
          type={type}
          error={error ? true : false}
          helperText={error}
          defaultValue={defaultValue}
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
    />
  );
}

export default CustomAutoComplete;
import { MY_FINANCIAL_FETCHED } from '../../utils/types';

const INITIAL_STATE = { person: {} };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MY_FINANCIAL_FETCHED:
      return { ...state, person: action.payload.data };
    default:
      return state;
  }
};

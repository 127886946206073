import React, { useEffect } from 'react';
import { makeStyles, Paper, Tabs } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Add as AddIcon, List as ListIcon, Edit as EditIcon } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';

import { TAB_SELECTED } from '../../../src/utils/types';
import { init } from './bookActions';
import BookList from './components/BookList';
import BookForm from './components/BookForm';
import TabPanel from '../../components/common/Tab/components/TabPanel';
import TabHeader from '../../components/common/Tab/components/TabHeader';
import LabelAndIcon from '../../components/common/Tab/components/LabelAndIcon';
import ContentHeader from '../../components/common/template/ContentHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  container: {
    // display: 'flex',
    // justifyContent: 'center'
  },
  containerTab: {
    // padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    // backgroundColor: '#CCC',
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

function Book() {
  const classes = useStyles();
  const data = useSelector(state => state.book.list);
  const dispatch = useDispatch();
  const theme = useTheme();
  const value = useSelector(state => state.tab.selected);

  const setValue = (newValue) => {
    dispatch({ type: TAB_SELECTED, payload: newValue })
  }

  useEffect(() => {
    init(dispatch, true);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <ContentHeader title='Livros' small='Cadastro' />
      <Paper square className={classes.container} color='default'>
        <div className={classes.containerTab}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
          >
            <TabHeader
              label={<LabelAndIcon icon={<ListIcon className={classes.icon} />} label='Listar' />}
              target='tabList'
            />
            <TabHeader
              label={<LabelAndIcon icon={<AddIcon className={classes.icon} />} label='Incluir' />}
              target='tabCreate'
            />
            <TabHeader
              label={<LabelAndIcon icon={<EditIcon className={classes.icon} />} label='Alterar' />}
              target='tabUpdate'
            />
          </Tabs>
        </div>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <BookList data={data} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <BookForm submitLabel='Incluir' />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <BookForm submitLabel='Alterar' />
          </TabPanel>
        </SwipeableViews>
      </Paper>
    </div>
  );
}

export default Book;
import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RouteWithLayout from '../components/RouteWithLayout';
import MainLayout from '../layouts/Main';
import People from '../pages/People';
import Dashboard from '../pages/Dashboard';
import Book from '../pages/Book';
import LendBook from '../pages/LendBook';
import BrotherBox from '../pages/BrotherBox';
import Client from '../pages/Client';
import Provider from '../pages/Provider';
import Accounts from '../pages/Accounts';
import MyFinancial from '../pages/MyFinancial';
import MyRegister from '../pages/MyRegister';
import Works from '../pages/Works';
import Calendar from '../pages/Calendar';

import { ACCESS_USER_TYPES } from '../utils/consts';
import Playlist from "../pages/Playlist";

function Routes() {
  const { user } = useSelector(state => state.signIn);
  // const user = { userType: ACCESS_USER_TYPES[4]._id };

  switch (user.userType) {
    case ACCESS_USER_TYPES[0]._id:
      return (
        <Router>
          <Switch>
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='/dashboard' />
            <RouteWithLayout component={MyFinancial} exact layout={MainLayout} path='/myFinancial' />
            <RouteWithLayout component={LendBook} exact layout={MainLayout} path='/lendBook' />
            <RouteWithLayout component={MyRegister} exact layout={MainLayout} path='/myRegister' />
            <RouteWithLayout component={Works} exact layout={MainLayout} path='/works' />
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='*' />
          </Switch>
        </Router>
      );
    case ACCESS_USER_TYPES[1]._id:
      return (
        <Router>
          <Switch>
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='/dashboard' />
            <RouteWithLayout component={People} exact layout={MainLayout} path='/people' />
            <RouteWithLayout component={Book} exact layout={MainLayout} path='/book' />
            <RouteWithLayout component={Client} exact layout={MainLayout} path='/client' />
            <RouteWithLayout component={Provider} exact layout={MainLayout} path='/provider' />
            <RouteWithLayout component={LendBook} exact layout={MainLayout} path='/lendBook' />
            <RouteWithLayout component={BrotherBox} exact layout={MainLayout} path='/brotherBox' />
            <RouteWithLayout component={Accounts} exact layout={MainLayout} path='/accounts' />
            <RouteWithLayout component={Works} exact layout={MainLayout} path='/works' />
            <RouteWithLayout component={Calendar} exact layout={MainLayout} path='/calendar' />
            <RouteWithLayout component={Playlist} exact layout={MainLayout} path='/playlist' />
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='*' />
          </Switch>
        </Router>
      );
    case ACCESS_USER_TYPES[2]._id:
      return (
        <Router>
          <Switch>
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='/dashboard' />
            <RouteWithLayout component={Client} exact layout={MainLayout} path='/client' />
            <RouteWithLayout component={Provider} exact layout={MainLayout} path='/provider' />
            <RouteWithLayout component={BrotherBox} exact layout={MainLayout} path='/brotherBox' />
            <RouteWithLayout component={Accounts} exact layout={MainLayout} path='/accounts' />
            <RouteWithLayout component={MyFinancial} exact layout={MainLayout} path='/myFinancial' />
            <RouteWithLayout component={LendBook} exact layout={MainLayout} path='/lendBook' />
            <RouteWithLayout component={MyRegister} exact layout={MainLayout} path='/myRegister' />
            <RouteWithLayout component={Works} exact layout={MainLayout} path='/works' />
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='*' />
          </Switch>
        </Router>
      );
    case ACCESS_USER_TYPES[3]._id:
      return (
        <Router>
          <Switch>
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='/dashboard' />
            <RouteWithLayout component={Book} exact layout={MainLayout} path='/book' />
            <RouteWithLayout component={LendBook} exact layout={MainLayout} path='/lendBook' />
            <RouteWithLayout component={MyFinancial} exact layout={MainLayout} path='/myFinancial' />
            <RouteWithLayout component={MyRegister} exact layout={MainLayout} path='/myRegister' />
            <RouteWithLayout component={Works} exact layout={MainLayout} path='/works' />
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='*' />
          </Switch>
        </Router>
      );
    case ACCESS_USER_TYPES[4]._id:
      return (
        <Router>
          <Switch>
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='/dashboard' />
            <RouteWithLayout component={People} exact layout={MainLayout} path='/people' />
            <RouteWithLayout component={MyFinancial} exact layout={MainLayout} path='/myFinancial' />
            <RouteWithLayout component={LendBook} exact layout={MainLayout} path='/lendBook' />
            <RouteWithLayout component={Works} exact layout={MainLayout} path='/works' />
            <RouteWithLayout component={Calendar} exact layout={MainLayout} path='/calendar' />
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='*' />
          </Switch>
        </Router>
      );
    case ACCESS_USER_TYPES[5]._id:
      return (
          <Router>
            <Switch>
              <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='/dashboard' />
              <RouteWithLayout component={MyFinancial} exact layout={MainLayout} path='/myFinancial' />
              <RouteWithLayout component={LendBook} exact layout={MainLayout} path='/lendBook' />
              <RouteWithLayout component={MyRegister} exact layout={MainLayout} path='/myRegister' />
              <RouteWithLayout component={Works} exact layout={MainLayout} path='/works' />
              <RouteWithLayout component={Calendar} exact layout={MainLayout} path='/calendar' />
              <RouteWithLayout component={Playlist} exact layout={MainLayout} path='/playlist' />
              <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='*' />
            </Switch>
          </Router>
      );
    default:
      return (
        <Router>
          <Switch>
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='/dashboard' />
            <RouteWithLayout component={MyFinancial} exact layout={MainLayout} path='/myFinancial' />
            <RouteWithLayout component={MyRegister} exact layout={MainLayout} path='/myRegister' />
            <RouteWithLayout component={Works} exact layout={MainLayout} path='/works' />
            <RouteWithLayout component={Dashboard} exact layout={MainLayout} path='*' />
          </Switch>
        </Router>
      );
  }
}

export default Routes;

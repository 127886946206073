import React from 'react';
import { Tab } from '@material-ui/core';
import { useSelector } from 'react-redux';

function TabHeader({ label, target, ...props }) {
    const tabs = useSelector(state => state.tab);
    const visible = tabs.visible[target];

    return visible ? <Tab {...props} label={label} /> : <div />;
}

export default TabHeader;
import { BOOK_FETCHED, BOOK_INIT } from '../../utils/types';

const INITIAL_STATE = { list: [], book: {} };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BOOK_FETCHED:
            return { ...state, list: action.payload.data.data };
        case BOOK_INIT:
            return { ...state, book: action.payload };
        default:
            return state;
    }
};

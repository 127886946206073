import axios from 'axios';
import { useSelector } from 'react-redux';
import consts from '../utils/consts';

function User() {
    const user = useSelector(state => state.signIn.user);
    console.log(user)
    return user.token;
}

const api = axios.create({
    baseURL: consts.API_URL,
    // headers: { 'authorization': User }
});

export default api;

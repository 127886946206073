import React, { useEffect } from 'react';
import { makeStyles, Paper, Tabs, Button, Typography, CircularProgress, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Add as AddIcon, List as ListIcon, Edit as EditIcon, Print as PrintIcon } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';

import { TAB_SELECTED } from '../../utils/types';
import { init, initPay, getBrotherList } from './accountsActions';
import { currencyFormat, isArrayEmpty } from '../../utils/functions';
import AReceivableList from './tabs/AReceivableList';
import AReceivedList from './tabs/AReceivedList';
import APayableList from './tabs/APayableList';
import AccountsForm from './tabs/AccountsForm';
import TabPanel from '../../components/common/Tab/components/TabPanel';
import TabHeader from '../../components/common/Tab/components/TabHeader';
import LabelAndIcon from '../../components/common/Tab/components/LabelAndIcon';
import ContentHeader from '../../components/common/template/ContentHeader';
import AccountsReport from './tabs/AccountsReport';
import { setBalance, sortListByDate } from '../BrotherBox/brotherBoxActions';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  containerTab: {
    backgroundColor: theme.palette.background.default,
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  actionButton: {
    marginRight: theme.spacing(1),
    minWidth: '100px'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  total: {
    marginLeft: theme.spacing(1)
  },
  containerProgress: {
    display: 'flex',
    minWidth: '180px',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

let brotherBoxTotal = '';

function Accounts() {
  const classes = useStyles();
  const dataAReceivable = useSelector(state => state.accounts.listReceivable);
  const receivedList = filterListReceivable(true);
  const receivableList = filterListReceivable(false);
  const dataAPayable = useSelector(state => state.accounts.listPayable);
  const dispatch = useDispatch();
  const theme = useTheme();
  const value = useSelector(state => state.tab.selected);
  const [state, setState] = React.useState({ showViewer: false, label: 'Imprimir' });
  const [brotherBox, setBrotherList] = React.useState([]);

  function filterListReceivable(received) {
    if (dataAReceivable) {
      if (received)
        return dataAReceivable.filter(item => item.status);
      else
        return dataAReceivable.filter(item => !item.hasOwnProperty('status') || !item.status);
    }
  }

  const handleClickOpen = () => {
    setState({
      showViewer: !state.showViewer,
      label: state.label === 'Imprimir' ? 'Fechar Impressão' : 'Imprimir'
    });
  };

  const setValue = (newValue) => {
    dispatch({ type: TAB_SELECTED, payload: newValue })
  }

  useEffect(() => {
    init(dispatch, true);
    initPay(dispatch, true, false);
    loadBrothersList();
  }, []);

  async function loadBrothersList() {
    const response = await getBrotherList();
    setBrotherList(response.data);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function renderHeader() {
    function getTotal(value, general) {
      let data = [];
      if (value === 0)
        data = [...receivableList];
      else if (value === 1)
        data = [...dataAPayable];
      else if (value === 2)
        data = [...receivedList];

      if (isArrayEmpty(data)) {
        const total = item => item.value;
        const sum = (acc, el) => acc + el

        const totalGeneral = data
          .map(total)
          .reduce(sum);

        return general && value === 0 ? totalGeneral + brotherBoxTotal : totalGeneral;
      }
      return '';
    }

    function renderTotalReceivable() {
      function returnTotal() {
        if (isArrayEmpty(brotherBox)) {
          const newList = [];
          brotherBox.forEach(list => {
            if (isArrayEmpty(list.brotherBox)) {
              const items = setBalance(sortListByDate(list.brotherBox));
              const item = items.pop();
              if (item.balance < 0) newList.push(item.balance);
            }
          });
          const sum = (acc, el) => acc + el;
          brotherBoxTotal = isArrayEmpty(newList) ? (newList.reduce(sum) * -1) : '';
          return brotherBoxTotal;
        }
      }

      const renderProgress = () => (
        <div className={classes.containerProgress}>
          <CircularProgress color='secondary' size={20} />
        </div>
      );

      return (
        !isArrayEmpty(brotherBox) ? renderProgress() :
          <div style={{ marginRight: 5 }}>
            <Typography variant='body1' className={classes.total}>
              Total Irmãos: {value === 0 ? currencyFormat(returnTotal()) : currencyFormat(0)}
            </Typography>
            <Typography variant='body1' className={classes.total}>
              Total Itens: {currencyFormat(getTotal(value))}
            </Typography>
          </div>
      )
    }

    return (
      <div className={classes.contentHeader}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={5} xs={12}>
            <ContentHeader title='Caixa da Loja' small='Financeiro' />
          </Grid>
          {value === 3 || value === 4 ?
            <div /> :
            <Grid item lg={6} md={7} xs={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  className={classes.actionButton}
                  onClick={handleClickOpen}
                >
                  <PrintIcon className={classes.icon} />
                  {state.label}
                </Button>
                {renderTotalReceivable()}
                <Typography variant='h4' className={classes.total}>
                  Total Geral: {currencyFormat(getTotal(value, true))}
                </Typography>
              </div>
            </Grid>
          }
        </Grid>
      </div>
    );
  }

  const renderTable = () => {
    return (
      <Paper square className={classes.container} color='default'>
        <div className={classes.containerTab}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons={window.innerWidth < 535 ? 'on' : 'auto'}
          >
            <TabHeader
              label={<LabelAndIcon icon={<ListIcon className={classes.icon} />} label='A Receber' />}
              target='tabListReceivable'
            />
            <TabHeader
              label={<LabelAndIcon icon={<ListIcon className={classes.icon} />} label='A Pagar' />}
              target='tabListPayable'
            />
            <TabHeader
              label={<LabelAndIcon icon={<ListIcon className={classes.icon} />} label='Recebidos' />}
              target='tabListReceived'
            />
            <TabHeader
              label={<LabelAndIcon icon={<AddIcon className={classes.icon} />} label='Incluir' />}
              target='tabCreate'
            />
            <TabHeader
              label={<LabelAndIcon icon={<EditIcon className={classes.icon} />} label='Alterar' />}
              target='tabUpdate'
            />
          </Tabs>
        </div>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <AReceivableList data={receivableList} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <APayableList data={dataAPayable} />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <AReceivedList data={receivedList} />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <AccountsForm submitLabel='Incluir' />
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <AccountsForm submitLabel='Alterar' />
          </TabPanel>
        </SwipeableViews>
      </Paper>
    );
  }

  return (
    <div className={classes.root}>
      {renderHeader()}
      {state.showViewer ?
        value === 0 ? <AccountsReport data={dataAReceivable} accountsMode={value} /> :
          <AccountsReport data={dataAPayable} accountsMode={value} />
        :
        renderTable()
      }
    </div>
  );
}

export default Accounts;
import { combineReducers } from '@reduxjs/toolkit';
import { reducer as toastrReducer } from 'react-redux-toastr';

import TabReducer from './components/common/Tab/tabReducer';
import SignIn from './pages/SignIn/signInReducer';
import Book from './pages/Book/bookReducer';
import People from './pages/People/peopleReducer';
import LendBook from './pages/LendBook/lendBookReducer';
import BrotherBox from './pages/BrotherBox/brotherBoxReducer';
import Client from './pages/Client/clientReducer';
import Provider from './pages/Provider/providerReducer';
import Accounts from './pages/Accounts/accountsReducer';
import MyFinancial from './pages/MyFinancial/myFinancialReducer';
import Works from './pages/Works/worksReducer';
import Calendar from './pages/Calendar/calendarReducer';
import Playlist from "./pages/Playlist/playlistReducer";

const rootReducer = combineReducers({
  toastr: toastrReducer,
  tab: TabReducer,
  signIn: SignIn,
  book: Book,
  people: People,
  lendBook: LendBook,
  brotherBox: BrotherBox,
  client: Client,
  provider: Provider,
  accounts: Accounts,
  myFinancial: MyFinancial,
  works: Works,
  calendar: Calendar,
  playlist: Playlist
});

export default rootReducer;

import { CLIENT_FETCHED, CLIENT_INIT } from '../../utils/types';

const INITIAL_STATE = { list: [], client: {} };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLIENT_FETCHED:
            return { ...state, list: action.payload.data.data };
        case CLIENT_INIT:
            return { ...state, client: action.payload };
        default:
            return state;
    }
};

import api from '../../services/api';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import consts from '../../utils/consts';
import { CALENDAR_FETCHED, CALENDAR_INIT, CALENDAR_PRESENCE_FETCHED } from '../../utils/types';

import { showTabs, selectTab } from '../../components/common/Tab/tabActions';

export function getList(dispatch, userLevel, isAdm) {
  axios({
    method: 'get',
    url: `${consts.API_URL}/calendars`,
    params: {
      level: isAdm ? userLevel : null
    }
  })
    .then(resp => {
      dispatch({ type: CALENDAR_FETCHED, payload: resp });
    })
    .catch(e => console.log(e));
}

export function getListPresence(dispatch, calendarId) {
  axios({
    method: 'get',
    url: `${consts.API_URL}/calendars/${calendarId}/presence`,
  })
      .then(resp => {
        dispatch({ type: CALENDAR_PRESENCE_FETCHED, payload: resp });
      })
      .catch(e => console.log(e));
}

export function create(values, dispatch) {
  return submit(values, 'post', dispatch);
}

export function update(values, dispatch) {
  return submit(values, 'put', dispatch);
}

export function remove(values, dispatch) {
  return submit(values, 'delete', dispatch);
}

function submit(values, method, dispatch) {
  const id = values._id ? values._id : '';
  axios[method](`${consts.API_URL}/calendars/${id}`, values)
    .then(resp => {
      toastr.success('Sucesso', 'Operação Realizada com sucesso.');
      init(dispatch, true);
    })
    .catch(e => {
      toastr.error('Erro', e.response.data);
    });
}

export function addPresence(dispatch, calendarId, personId) {
  axios({
    method: 'post',
    url: `${consts.API_URL}/calendars/${calendarId}/presence/${personId}`,
  })
      .then(resp => {
        dispatch([
            getListPresence(dispatch, calendarId),
      ]);
      })
      .catch(e => console.log(e));
}

export function removePresence(dispatch, calendarId, personId) {
  axios({
    method: 'delete',
    url: `${consts.API_URL}/calendars/${calendarId}/presence/${personId}`,
  })
      .then(resp => {
        dispatch(getListPresence(dispatch, calendarId));
      })
      .catch(e => console.log(e));
}

export function init(dispatch, reload) {
  dispatch([
    initCalendar(dispatch, {}),
    showTabs(dispatch, 'tabList', 'tabCreate'),
    selectTab(dispatch, 0),
    reload && getList(dispatch)
  ]);
}

export function showUpdate(dispatch, calendar) {
  dispatch([
    showTabs(dispatch, 'tabUpdate'),
    selectTab(dispatch, 2),
    initCalendar(dispatch, calendar)
  ]);
}

export function showPresence(dispatch, calendar) {
  dispatch([
    showTabs(dispatch, 'tabPresence'),
    selectTab(dispatch, 3),
    initCalendar(dispatch, calendar)
  ]);
}

function initCalendar(dispatch, value) {
  dispatch({ type: CALENDAR_INIT, payload: value });
}

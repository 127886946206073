import { PEOPLE_FETCHED, BROTHER_BOX_INIT, BROTHER_BOX_FETCHED, PERSON_BOX_INIT, LOADING_PDF, SHOW_ERRORS_SEND_EMAILS } from '../../utils/types';

const INITIAL_STATE = { list: [], person: {}, brotherBox: {}, showBrother: false, loadingPdf: false, errorsEmails: [] };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PEOPLE_FETCHED:
      return { ...state, list: action.payload.data.data };
    case PERSON_BOX_INIT:
      return { ...state, person: action.payload };
    case BROTHER_BOX_INIT:
      return { ...state, brotherBox: action.payload };
    case BROTHER_BOX_FETCHED:
      return { ...state, showBrother: action.payload };
    case LOADING_PDF:
      return { ...state, loadingPdf: action.payload };
    case SHOW_ERRORS_SEND_EMAILS:
      return { ...state, errorsEmails: action.payload };
    default:
      return state;
  }
};

import React, { useRef } from 'react';
import {
  makeStyles, CardContent, Button, Typography, Grid, InputAdornment, CircularProgress, CardActions, Divider,
} from '@material-ui/core';
import {Calendar as CalendarIcon, CalendarToday, Description as DescriptionIcon} from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { init, create, update, remove } from '../../calendarActions';
import { LEVEL_PERSON_LIST } from '../../../../utils/consts';
import CustomTextField from '../../../../components/common/form/CustomTextField';
import CustomSelect from '../../../../components/common/form/CustomSelect';
import CustomDatePicker from "../../../../components/common/form/CustomDatePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  container: {
    maxHeight: 440,
    padding: theme.spacing(1)
  },
  btn: {
    width: '10%',
    minWidth: '100px'
  },
  cardContent: {
    paddingBottom: theme.spacing(3)
  },
  cardActions: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function List({ submitLabel }) {
  const classes = useStyles();
  const [utils, setUtils] = React.useState({
    showPassword: false,
    loading: false,
    disabled: false,
  });
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const calendar = useSelector(state => state.calendar.calendar);
  const { user } = useSelector(state => state.signIn);

  async function handleSubmit(data) {
    try {
      const shema = Yup.object().shape({
        session: Yup.string().required('Sessão é obrigatório'),
        activity: Yup.string().required('A atividade é obrigatória'),
        responsible: Yup.string().required('O responsavel é obrigatório'),
        eventAt: Yup.string().required('A data do evento é obrigatório')
      });

      await shema.validate(data, { abortEarly: false });

      setUtils({ ...utils, loading: true, disabled: true });
      // setTimeout(() => {
      //     console.log(data);
      //     setUtils({ ...utils, loading: false, disabled: false });
      // }, 3000);

      if (submitLabel === 'Incluir') create(data, dispatch);
      else if (submitLabel === 'Alterar') {
        data._id = calendar._id;
        update(data, dispatch);
      } else if (submitLabel === 'Excluir') remove(data, dispatch);
      formRef.current.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage = {};

        err.inner.forEach(error => {
          errorMessage[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessage);
      }
    }
    setUtils({ ...utils, loading: false, disabled: false });
  }

  function handleCancel() {
    init(dispatch, true);
  }

  if (user.userType === 'VENERAVEL' || user.userType === 'CHANCELER') {
    return (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={calendar}>
          <div className={classes.root}>
            <CardContent className={classes.cardContent}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <CustomDatePicker size='small' fullWidth label='Data do evento' name='eventAt' type='text'  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                      className={classes.textField}
                      size='small'
                      fullWidth
                      label='Sessão*'
                      name='session'
                      type='text'
                      variant='outlined'
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                      name='activity'
                      variant='outlined'
                      className={classes.textField}
                      label='Atividade*'
                      fullWidth
                      size='small'
                      type='text'
                      variant='outlined'
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField
                      className={classes.textField}
                      size='small'
                      fullWidth
                      label='Responsável*'
                      name='responsible'
                      type='text'
                      variant='outlined'
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardActions}>
              <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  className={classes.btn}
                  disabled={utils.disabled}
                  type='submit'
                  disabled={utils.disabled}
              >
                {utils.loading ?
                    <CircularProgress color='primary' size={24} /> :
                    <Typography variant='button' color='inherit'>{submitLabel}</Typography>}
              </Button>
              <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  className={classes.btn}
                  onClick={handleCancel}
              >
                Cancelar
              </Button>
            </CardActions>
          </div>
        </Form>
    );
  }

  return null
}

export default List;

import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import consts, { ACCESS_USER_TYPES } from '../../utils/consts';
import { WORK_FETCHED, WORK_INIT } from '../../utils/types';

import { showTabs, selectTab } from '../../components/common/Tab/tabActions';

export function getList(dispatch, typeLevel) {
  axios({
    method: 'get',
    url: `${consts.API_URL}/works`,
    params: {
      level: typeLevel
    }
  })
    .then(resp => {
      dispatch({ type: WORK_FETCHED, payload: resp });
    })
    .catch(e => console.log(e));
}

export function create(values, dispatch) {
  return submit(values, 'post', dispatch);
}

export function update(values, dispatch, data) {
  return submit(values, 'put', dispatch, data);
}

export function remove(values, dispatch) {
  return submit(values, 'delete', dispatch);
}

function submit(values, method, dispatch, dataUpdate) {
  const id = values.get('_id') ? values.get('_id') : '';
  let url = `${consts.API_URL}/works/${id}`;
  if (values.get('file') === 'null') {
    url = `${url}/withoutFile`;
    values = dataUpdate;
  }
  
  axios[method](url, values)
    .then(resp => {
      toastr.success('Sucesso', 'Operação Realizada com sucesso.');
      init(dispatch, true);
    })
    .catch(e => {
      toastr.error('Erro', e.response.data);
    });
}

export function init(dispatch, reload, typeLevel, userType) {
  dispatch([
    initWork(dispatch, {}),
    userType === ACCESS_USER_TYPES[1]._id ? showTabs(dispatch, 'tabList', 'tabCreate') : showTabs(dispatch, 'tabList'),
    selectTab(dispatch, 0),
    reload && getList(dispatch, typeLevel)
  ]);
}

export function showUpdate(dispatch, book) {
  dispatch([
    showTabs(dispatch, 'tabUpdate'),
    selectTab(dispatch, 2),
    initWork(dispatch, book)
  ]);
}

function initWork(dispatch, value) {
  dispatch({ type: WORK_INIT, payload: value });
}

export function downloadFile(id, filename) {
  axios.get(`${consts.API_URL}/works/${id}/download`, { responseType: 'blob' })
    .then(resp => {
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      toastr.success('Sucesso', 'Operação Realizada com sucesso.');
    })
    .catch(e => {
      console.log(e);
      toastr.error('Erro', e.hasOwnProperty('response') && e.response.data);
    });
}
import React from 'react';
import { makeStyles, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';

import { showUpdate } from '../../clientActions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 505,
  },
  icon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  }
}));

function List({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = (client) => {
    showUpdate(dispatch, client);
  }

  const renderRow = () => {
    return data.map(client => (
      <TableRow
        key={client._id}
        hover
        // onClick={() => handleClick(client)}
      >
        <TableCell>{client.name}</TableCell>
        <TableCell>{client.cpfCnpj}</TableCell>
        <TableCell>{client.rg}</TableCell>
        <TableCell>{client.address.phone}</TableCell>
        <TableCell>
          <IconButton aria-label='edit' color='primary' size='small' onClick={() => handleClick(client)}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label='delete' color='secondary' className={classes.icon} size='small'>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>CPF/CNPJ</TableCell>
            <TableCell>RG</TableCell>
            <TableCell>Telefone</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRow()}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default List;
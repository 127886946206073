import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Scope } from '@unform/core';

import CustomTextField from '../../../../../components/common/form/CustomTextField';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

function Address() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Scope path='address'>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <CustomTextField size='small' fullWidth label='Endereço' name='street' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Bairro' name='neighbor' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Número' name='number' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Complemento' name='complement' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Cidade' name='city' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Estado' name='state' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='CEP' name='postalCode' type='text' variant='outlined' mask='postalCode' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Celular' name='cellphone' type='text' variant='outlined' mask='cellphone' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Telefone' name='phone' type='text' variant='outlined' mask='phone' />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomTextField size='small' fullWidth label='E-mail' name='email' type='text' variant='outlined' />
        </Grid>
      </Grid>
      </Scope>
    </div>
  );
}

export default Address;
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    // position: 'relative',
    // bottom: 0,
    // left: 0
  }
}));

function Footer(props) {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant='body1'>
        &copy;{' '}
        <Link
          component='a'
          href='http://www.p2it.com.br/'
          target='_blank'
        >
          P2IT
        </Link>
        . 2020
      </Typography>
      {/* <Typography variant='caption'>
        Created with love for the environment. By designers and developers who
        love to work together in offices!
      </Typography> */}
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;

import React, { useRef, useEffect } from 'react';
import {
  makeStyles, CardContent, Button, Typography, Grid, CircularProgress, CardActions, Divider, Radio,
  RadioGroup, FormControlLabel, FormControl
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { init, initPay, create, update, setAccountsMode } from '../../accountsActions';
import { replaceValue, isArrayEmpty, isEmpty } from '../../../../utils/functions';
import { getList as getClientList } from '../../../Client/clientActions';
import { getList as getProviderList } from '../../../Provider/providerActions';
import { ACCOUNTS_GROUPS, PAYMENT_MODE, CARRIERS, CLIENT_SELECTED, PROVIDER_SELECTED } from '../../../../utils/consts';
import CustomTextField from '../../../../components/common/form/CustomTextField';
import CustomSelect from '../../../../components/common/form/CustomSelect';
import CustomDatePicker from '../../../../components/common/form/CustomDatePicker';
import CustomCheckbox from '../../../../components/common/form/CustomCheckbox';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  container: {
    maxHeight: 440,
    padding: theme.spacing(1)
  },
  btn: {
    width: '10%',
    minWidth: '100px'
  },
  cardContent: {
    paddingBottom: theme.spacing(3)
  },
  cardActions: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  containerRadio: {
    display: 'flex'
  }
}));

function ClientForm({ submitLabel }) {
  const classes = useStyles();
  const [utils, setUtils] = React.useState({
    loading: false,
    disabled: false,
  });
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const aReceivable = useSelector(state => state.accounts.accountReceivable);
  const aPayable = useSelector(state => state.accounts.accountPayable);
  let accountsMode = useSelector(state => state.accounts.accountsMode);
  const clientList = useSelector(state => state.client.list);
  const providerList = useSelector(state => state.provider.list);

  useEffect(() => {
    getClientList(dispatch);
    getProviderList(dispatch);
  }, []);

  const handleChange = (event) => {
    setAccountsMode(dispatch, event.target.value);
    // setRadio(event.target.value);
  };

  async function handleSubmit(data) {
    data.person = data.hasOwnProperty('client') ? data.client : data.provider;
    const label = accountsMode === 'aPayable' ? 'provider' : 'client';
    const message = accountsMode === 'aPayable' ? 'O fornecedor é obrigatório' : 'O cliente é obrigatório';
    const item = accountsMode === 'aPayable' ? PROVIDER_SELECTED.value : CLIENT_SELECTED.value
    try {
      const shema = Yup.object().shape({
        value: Yup.string().required('O valor é obrigatório'),
        issuanceDate: Yup.string().required('A emissão é obrigatória'),
        dueDate: Yup.string().required('O vencimento é obrigatório'),
        person: Yup.string().required(message).notOneOf([item], message),
      });

      await shema.validate(data, { abortEarly: false });

      setUtils({ ...utils, loading: true, disabled: true });

      data.value = replaceValue(data.value);

      delete Object.assign(data, { [label]: data['person'] })['person'];
      console.log(data);

      if (submitLabel === 'Incluir') create(data, dispatch, accountsMode);
      else if (submitLabel === 'Alterar') {
        data._id = accountsMode === 'aReceivable' ? aReceivable._id : aPayable._id;
        update(data, dispatch, accountsMode);
      }

      formRef.current.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage = {};
        
        err.inner.forEach(error => {
          if (error.path === 'person')
            errorMessage[accountsMode === 'aPayable' ? 'provider' : 'client'] = error.message;
          else
            errorMessage[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessage);
      }
    }
    setUtils({ ...utils, loading: false, disabled: false });
  }

  function handleCancel() {
    if (accountsMode === 'aReceivable') init(dispatch, true, false);
    else if (accountsMode === 'aReceived') init(dispatch, true, true);
    else if (accountsMode === 'aPayable') initPay(dispatch, true, true);
  }

  function renderRadio() {
    let accountsModeRadio = accountsMode;
    if (accountsModeRadio === 'aReceived') accountsModeRadio = 'aReceivable';
    return (
      <FormControl component='fieldset'>
        <RadioGroup row aria-label='gender' name='gender1' value={accountsModeRadio} onChange={handleChange} className={classes.containerRadio}>
          <FormControlLabel value='aReceivable' control={<Radio />} label='Crédito' />
          <FormControlLabel value='aPayable' control={<Radio />} label='Débito' />
        </RadioGroup>
      </FormControl>
    );
  }

  function renderPerson() {
    if (accountsMode === 'aReceivable' && !isArrayEmpty(clientList))
      return <CircularProgress color='primary' size={24} />
    else if (accountsMode === 'aReceived' && !isArrayEmpty(clientList))
      return <CircularProgress color='primary' size={24} />
    else if (accountsMode === 'aPayable' && !isArrayEmpty(providerList))
      return <CircularProgress color='primary' size={24} />
    else {
      const label = accountsMode === 'aPayable' ? 'Fornecedor' : 'Cliente'
      return accountsMode === 'aPayable' ?
        <CustomSelect
          name='provider'
          variant='outlined'
          className={classes.formControl}
          label={label}
          fullWidth
          size='small'
          options={providerArray(providerList)}
        />
        :
        <CustomSelect
          name='client'
          variant='outlined'
          className={classes.formControl}
          label={label}
          fullWidth
          size='small'
          options={clientArray(clientList)}
        />
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={accountsMode === 'aPayable' ? aPayable : aReceivable}>
      <div className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              {renderRadio()}
            </Grid>
            <Grid item md={3} xs={12}>
              {renderPerson()}
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomDatePicker
                className={classes.textField}
                size='small'
                fullWidth
                label='Emissão'
                name='issuanceDate'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Valor'
                name='value'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomDatePicker
                className={classes.textField}
                size='small'
                fullWidth
                label='Vencimento'
                name='dueDate'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomSelect
                name='group'
                variant='outlined'
                className={classes.formControl}
                label='Grupo'
                fullWidth
                size='small'
                options={ACCOUNTS_GROUPS}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomSelect
                name='paymentMode'
                variant='outlined'
                className={classes.formControl}
                label='Modo de Pagamento'
                fullWidth
                size='small'
                options={PAYMENT_MODE}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomSelect
                name='carrier'
                variant='outlined'
                className={classes.formControl}
                label='Portador'
                fullWidth
                size='small'
                options={CARRIERS}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Histórico'
                name='history'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Observação'
                multiline
                rows={2}
                name='observation'
                type='text'
                variant='outlined'
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className={classes.btn}
            disabled={utils.disabled}
            type='submit'
            disabled={utils.disabled}
          >
            {utils.loading ?
              <CircularProgress color='primary' size={24} /> :
              <Typography variant='button' color='inherit'>{submitLabel}</Typography>}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            className={classes.btn}
            onClick={handleCancel}
          >
            Cancelar
                        </Button>
        </CardActions>
      </div>
    </Form>
  );

  function clientArray(list) {
    let array = [];
    array[0] = CLIENT_SELECTED;
    if (list !== undefined) {
      array = list.map(item => {
        return { '_id': item._id, value: item.name };
      });
      array.unshift(CLIENT_SELECTED);
    }
    return array;
  }

  function providerArray(list) {
    let array = [];
    array[0] = PROVIDER_SELECTED;
    if (list !== undefined) {
      array = list.map(item => {
        return { '_id': item._id, value: item.name };
      });
      array.unshift(PROVIDER_SELECTED);
    }
    return array;
  }
}

export default ClientForm;
import React, { useRef } from 'react';
import {
  makeStyles, CardContent, Button, Typography, Grid, TextField, CircularProgress, CardActions, Divider,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { CloudUpload } from '@material-ui/icons';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { init, create, update, remove } from '../../worksActions';
import { LEVEL_PERSON_LIST } from '../../../../utils/consts';
import CustomTextField from '../../../../components/common/form/CustomTextField';
import CustomSelect from '../../../../components/common/form/CustomSelect';
import { existsContent, convertSize } from '../../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  btn: {
    width: '10%',
    minWidth: '100px'
  },
  cardContent: {
    paddingBottom: theme.spacing(3)
  },
  cardActions: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  containerFile: {
    display: 'flex'
  },
  btnSearch: {
    marginLeft: theme.spacing(1)
  },
  extendedIcon: {
    marginLeft: theme.spacing(1)
  }
}));

function List({ submitLabel, typeLevel, userType }) {
  const classes = useStyles();
  const work = useSelector(state => state.works.work);
  const [utils, setUtils] = React.useState({
    showPassword: false,
    loading: false,
    disabled: false,
    fileUploaded: null,
    filename: work.filename,
    errorMessage: ''
  });
  const formRef = useRef(null);
  const dispatch = useDispatch();

  async function handleSubmit(data) {
    data.filename = utils.fileUploaded ? utils.fileUploaded.name : null;
    if (!existsContent(data.filename) && submitLabel !== 'Alterar')
      setUtils({ ...utils, errorMessage: 'O arquivo é obrigatório' });
    else {
      try {
        const shema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          level: Yup.string().required('O grau é obrigatório')
        });

        await shema.validate(data, { abortEarly: false });

        setUtils({ ...utils, loading: true, disabled: true });

        const newData = new FormData();
        newData.append('name', data.name);
        newData.append('level', data.level);
        newData.append('file', utils.fileUploaded)

        if (submitLabel === 'Incluir') create(newData, dispatch);
        else if (submitLabel === 'Alterar') {
          newData.append('_id', work._id);
          data._id = work._id;
          update(newData, dispatch, data);
        } else if (submitLabel === 'Excluir') remove(newData, dispatch);
        formRef.current.setErrors({});
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessage = {};

          err.inner.forEach(error => {
            errorMessage[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessage);
        }
      }
      setUtils({ ...utils, loading: false, disabled: false, errorMessage: null });
    }
  }

  function handleCancel() {
    init(dispatch, true, typeLevel, userType);
  }

  function onChange({ target: { files } }) {
    setUtils({ ...utils, fileUploaded: files[0], filename: files[0].name });
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={work}>
      <div className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Nome*'
                name='name'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomSelect
                name='level'
                variant='outlined'
                className={classes.formControl}
                label='Grau*'
                fullWidth
                size='small'
                options={LEVEL_PERSON_LIST}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <div className={classes.containerFile}>
                <TextField
                  name='filename'
                  variant='outlined'
                  className={classes.textField}
                  label='Arquivo*'
                  fullWidth
                  size='small'
                  type='text'
                  variant='outlined'
                  disabled
                  value={utils.filename || ''}
                  onChange={(e) => setUtils({ ...utils, filename: e.target.value })}
                  error={existsContent(utils.errorMessage) ? true : false}
                  helperText={existsContent(utils.errorMessage) ? 'O arquivo é obrigatório' : null}
                />
                <input
                  color='primary'
                  accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, image/*'
                  type='file'
                  onChange={onChange}
                  id='icon-button-file'
                  style={{ display: 'none' }}
                />
                <label htmlFor='icon-button-file'>
                  <Button
                    variant='outlined'
                    color='primary'
                    component='span'
                    className={classes.btnSearch}
                  >
                    Procurar
                    <CloudUpload className={classes.extendedIcon} />
                  </Button>
                </label>
              </div>
            </Grid>
            {utils.fileUploaded &&
              <Grid item md={6} xs={12}>
                <TextField
                  name='size'
                  variant='outlined'
                  className={classes.textField}
                  label='Tamanho'
                  fullWidth
                  size='small'
                  type='text'
                  variant='outlined'
                  disabled
                  value={convertSize(utils.fileUploaded.size)}
                />
              </Grid>
            }
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className={classes.btn}
            disabled={utils.disabled}
            type='submit'
            disabled={utils.disabled}
          >
            {utils.loading ?
              <CircularProgress color='primary' size={24} /> :
              <Typography variant='button' color='inherit'>{submitLabel}</Typography>}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            className={classes.btn}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </CardActions>
      </div>
    </Form>
  );
}

export default List;
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import { configureStore, applyMiddleware, createStore, compose } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import multi from 'redux-multi';
import thunk from 'redux-thunk';

import './global.css';
import theme from './theme';
import reducers from './reducers';
import AuthOrApp from './authOrApp';


const devTools = window.__REDUX_DEVTOOLS_EXTENSION__
  && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = applyMiddleware(multi, thunk)(createStore)(reducers, devTools);

// const store = configureStore({
//     reducer: reducers
// });

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AuthOrApp />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

import React, { useEffect, useRef } from 'react';
import {
  makeStyles, CardContent, Button, Typography, Grid, InputAdornment, CircularProgress, CardActions, Divider
} from '@material-ui/core';
// import { Book as BookIcon, Description as DescriptionIcon, DoneOutline as DoneIcon } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toastr } from 'react-redux-toastr';

import { init, create, update, remove } from '../../lendBookActions';
import { PERSON_SELECTED, BOOK_SELECTED, STATUS_BOOK_LIST } from '../../../../utils/consts';
import { getList as getBookList } from '../../../Book/bookActions';
import { getList as getPersonList } from '../../../People/peopleActions';
import { isArrayEmpty, compressArray } from '../../../../utils/functions';
import CustomTextField from '../../../../components/common/form/CustomTextField';
import CustomSelect from '../../../../components/common/form/CustomSelect';
import CustomDatePicker from '../../../../components/common/form/CustomDatePicker';
import LendItems from '../../LendItems';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  btn: {
    width: '10%',
    minWidth: '100px'
  },
  cardContent: {
    paddingBottom: theme.spacing(3)
  },
  cardActions: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
}));

let temporaryItems = [];

function List({ submitLabel, isRequestBook, user }) {
  const classes = useStyles();
  const [utils, setUtils] = React.useState({
    showPassword: false,
    loading: false,
    disabled: false,
  });
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const lendBook = useSelector(state => state.lendBook.lendBook);
  const bookList = useSelector(state => state.book.list);
  const peopleList = useSelector(state => state.people.list);

  useEffect(() => {
    temporaryItems = lendBook.lendItems;
    getBookList(dispatch, user && user.userLevel, isRequestBook && true);
    getPersonList(dispatch);
  }, []);

  if (isRequestBook && peopleList.length > 0 && !lendBook.hasOwnProperty('person')) {
    const person = peopleList.filter(item => item._id === user.id);
    lendBook.person = person[0];
  }

  async function handleSubmit(data) {
    const hasAmount = checkIfContainsAmount(data.lendItems, bookList);
    try {
      const shema = Yup.object().shape({
        date: Yup.string().required('A data é obrigatória'),
        person: Yup.string().required('A pessoa é obrigatória').notOneOf([PERSON_SELECTED.value], 'A pessoa é obrigatória'),
        lendItems: Yup.array().of(
          Yup.object().shape({
            returnDate: Yup.string().required('A data é obrigatória'),
            // amount: Yup.number().typeError('Valor inválido').required('A quantidade é obrigatória').positive('Valor inválido').integer('Valor tem que ser inteiro'),
            book: Yup.string().required('O livro é obrigatório').notOneOf([BOOK_SELECTED.value], 'O livro é obrigatório'),
          })
        )
      });

      await shema.validate(data, { abortEarly: false });

      if (hasAmount) {
        setUtils({ ...utils, loading: true, disabled: true });
        console.log(data);

        if (submitLabel === 'Incluir' || submitLabel === 'Solicitar') create(data, isRequestBook, dispatch, user ? user.id : undefined);
        else if (submitLabel === 'Alterar') {
          data._id = lendBook._id;
          data.newAmountsBook = calculateAmount(temporaryItems, data.lendItems, bookList);
          update(data, isRequestBook, dispatch, user.id);
        } else if (submitLabel === 'Excluir') remove(data, dispatch);
      }
      formRef.current.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage = {};

        err.inner.forEach(error => {
          errorMessage[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessage);
      }
    }
    setUtils({ ...utils, loading: false, disabled: false });
  }

  function checkIfContainsAmount(items, bookList) {
    function checkBeforeList(bookId, list) {
      const beforeList = list
        .filter(item => item._id !== null)
        .map(item => item.book._id);
      return beforeList.includes(bookId);
    }
    let checkList = [];
    if (isArrayEmpty(bookList)) {
      const newArray = compressArray(items.map(item => item.book));

      newArray.forEach((item, index) => {
        const bookSelected = bookList.filter(book => book._id === item.value);

        if (bookSelected.length > 0) {
          newArray.forEach(item => {
            if (item.value === bookSelected[0]._id) {
              const amount = bookSelected[0].amount;
              const bookAlreadyExisted = checkBeforeList(bookSelected[0]._id, lendBook.lendItems);
              // console.log(bookAlreadyExisted);
              // const newAmount = amount - item.count;
              const newAmount = bookAlreadyExisted ? amount - (item.count - 1) : amount - item.count;
              if (newAmount < 0) {
                const message = `O livro ${bookSelected[0].title} possuí um estoque de: ${bookSelected[0].amount}`;
                toastr.warning('Alerta', message);
                checkList[index] = false;
              } else {
                // console.log(`O livro ${bookSelected[0].title} possuí estoque disponível. :)`);
                checkList[index] = true;
              }
            }
          });
        }
      });
    }
    return !checkList.includes(false);
  }

  function calculateAmount(beforeItems, newItems, bookList) {
    let newAmountsBook = [];
    if (isArrayEmpty(newItems)) {
      const newArray = compressArray(newItems.map(item => item.book));
      for (let i = 0; i < newArray.length; i++) {
        const bookSelected = bookList.filter(book => book._id === newArray[i].value)[0];
        if (bookSelected !== undefined && bookSelected.amount > 0) {
          const oldBook = compressArray(beforeItems.map(item => item.book)).filter(item => item.value._id === bookSelected._id);
          if (oldBook.length > 0) {
            const newAmount = bookSelected.amount - (newArray[i].count - oldBook[0].count)
            newAmountsBook.push({
              '_id': bookSelected._id,
              amountAvailable: bookSelected.amount,
              newAmount
            });
          } else
            newAmountsBook.push({
              '_id': bookSelected._id,
              amountAvailable: bookSelected.amount,
              newAmount: bookSelected.amount - newArray[i].count
            });
        }
      }
      const beforeIds = beforeItems.map(item => item.book._id);
      const newItemsIds = newItems.map(item => item.book);
      const intersection = beforeIds.filter(item => !newItemsIds.includes(item));
      if (isArrayEmpty(intersection)) {
        for (let i = 0; i < intersection.length; i++) {
          const itemList = beforeItems
            .filter(item => item.book._id === intersection[i])[0];

          newAmountsBook.push({
            '_id': intersection[i],
            amountAvailable: itemList.book.amount,
            newAmount: itemList.book.amount + 1
          });
        }
      }
      // console.log(newAmountsBook);
    }
    return newAmountsBook;
  }

  function handleCancel() {
    init(dispatch, isRequestBook ? false : true, isRequestBook);
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={lendBook}>
      <div className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item md={2} xs={12}>
              <CustomDatePicker
                className={classes.textField}
                size='small'
                fullWidth
                label='Data*'
                name='date'
                type='text'
              />
            </Grid>
            <Grid item md={4} xs={12}>
              {!isArrayEmpty(peopleList) ?
                <CircularProgress color='primary' size={24} /> :
                <CustomSelect
                  name='person'
                  variant='outlined'
                  className={classes.formControl}
                  label='Irmão*'
                  fullWidth
                  size='small'
                  options={peopleArray(peopleList)}
                  defaultObj
                  disabled={isRequestBook}
                />
              }
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomSelect
                name='status'
                variant='outlined'
                className={classes.formControl}
                label='Status'
                fullWidth
                size='small'
                options={STATUS_BOOK_LIST.slice(0, -1)}
                disabled={isRequestBook}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Observação'
                name='observation'
                type='text'
                variant='outlined'
                multiline
                rows={2}
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position='start'>
              //       <DescriptionIcon fontSize='small' />
              //     </InputAdornment>
              //   )
              // }}
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <LendItems data={lendBook.lendItems} name='lendItems' bookList={bookArray(bookList)} formRef={formRef} idLend={lendBook._id} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className={classes.btn}
            disabled={utils.disabled}
            type='submit'
            disabled={utils.disabled}
          >
            {utils.loading ?
              <CircularProgress color='primary' size={24} /> :
              <Typography variant='button' color='inherit'>{submitLabel}</Typography>}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            className={classes.btn}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </CardActions>
      </div>
    </Form>
  );

  function bookArray(bookList) {
    let array = [];
    array[0] = BOOK_SELECTED;
    if (bookList !== undefined) {
      array = bookList.map(item => {
        return { '_id': item._id, value: item.title };
      });
      array.splice(0, 0, BOOK_SELECTED);
    }
    return array;
  }

  function peopleArray(peopleList) {
    let array = [];
    array[0] = PERSON_SELECTED;
    if (peopleList !== undefined) {
      array = peopleList.map(item => {
        return { '_id': item._id, value: item.name };
      });
      array.splice(0, 0, PERSON_SELECTED);
    }
    return array;
  }
}

export default List;
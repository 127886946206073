import { TAB_SELECTED, TAB_SHOWED } from '../../../utils/types';

export function selectTab(dispatch, tabId) {
    dispatch ({
        type: TAB_SELECTED,
        payload: tabId
    });
}

export function showTabs(dispatch, ...tabIds) {
    const tabsToShow = {};
    tabIds.forEach(e => tabsToShow[e] = true);
    dispatch({
        type: TAB_SHOWED,
        payload: tabsToShow
    });
}

import React from 'react';
import {
  makeStyles, CardContent, Button, Typography, Grid, CircularProgress, CardActions, Divider,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { sendEmailForAll, init } from '../../brotherBoxActions';
import { isArrayEmpty } from '../../../../utils/functions';
import SendEmailDialog from '../SendEmailDialog';
import CustomDialog from '../../../../components/common/form/Dialog';

const useStyles = makeStyles((theme) => ({
  btn: {
    width: '10%',
    minWidth: '100px'
  },
  cardContent: {
    paddingBottom: theme.spacing(3)
  },
  cardActions: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  containerMessage: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  error: {
    marginLeft: theme.spacing(1)
  }
}));

function SendEmailForAll({ submitLabel }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const errorsEmails = useSelector(state => state.brotherBox.errorsEmails);
  const [utils, setUtils] = React.useState({ loading: false, disabled: false });
  const [open, setOpen] = React.useState(false);

  const handleConfirm = (period, data) => {
    function callback() {
      setUtils({ ...utils, loading: false, disabled: false })
    }
    setUtils({ ...utils, loading: true, disabled: true });
    setOpen(false);
    if (period === 'all')
      sendEmailForAll({ option: period }, dispatch, callback);
    else
      sendEmailForAll({ option: period, initialDate: data.initialDate, finalDate: data.finalDate }, dispatch, callback);

    // setTimeout(() => setUtils({ ...utils, loading: false, disabled: false }), 3000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleCancel() {
    init(dispatch, false)
  }

  async function handleSubmit() {
    setOpen(true);
  }

  function renderErrors() {
    return (
      <div>
        <Typography variant='h5' color='inherit'>Lista de erros:</Typography>
        {errorsEmails.map(item =>
          <div className={classes.containerMessage}>
            <Typography variant='subtitle2' color='inherit'>- {item.person.name}:</Typography>
            <Typography variant='subtitle1' color='error' className={classes.error}>{item.message}</Typography>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={3}>
          {isArrayEmpty(errorsEmails) === 0 ?
            <Typography variant='h4' color='inherit'>Deseja realmente enviar o e-mail para todos os irmãos?</Typography> :
            renderErrors()
          }
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Button
          variant='contained'
          color='primary'
          size='small'
          className={classes.btn}
          disabled={utils.disabled}
          onClick={handleSubmit}
        >
          {utils.loading ?
            <CircularProgress color='primary' size={24} /> :
            <Typography variant='button' color='inherit'>{submitLabel}</Typography>}
        </Button>
        <Button
          variant='contained'
          color='secondary'
          size='small'
          className={classes.btn}
          onClick={handleCancel}
        >
          Cancelar
          </Button>
      </CardActions>
      <SendEmailDialog
        open={open}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
      {/* <CustomDialog open={open} handleClose={handleClose} handleConfirm={handleConfirm} context='Deseja realmente enviar o e-mail para todos os irmãos?' /> */}
    </div>
  );
}

export default SendEmailForAll;
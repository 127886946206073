import React, { useRef } from 'react';
import { makeStyles, Card, CardContent, Button, Typography, Link, InputAdornment, IconButton, CircularProgress, TextField } from '@material-ui/core';
import { AccountCircle, AccountCircleOutlined, Email, Lock, Visibility, VisibilityOff, Smartphone } from '@material-ui/icons';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { login, signup } from '../SignIn/signInActions';
import CustomTextField from '../../components/common/form/CustomTextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '1120',
    height: '100vh',
    margin: theme.spacing(0, 'auto'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 500
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  btn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function SignUp() {
  const classes = useStyles();
  const loginMode = useSelector(state => state.signIn.loginMode);
  const [utils, setUtils] = React.useState({
    showPassword: false,
    showConfirmPassword: false,
    loading: false,
    disabled: false
  });
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  loginMode && history.push('/sign-in');

  async function handleSubmit(data) {
    try {
      const shema = Yup.object().shape({
        name: Yup.string().min(6, 'O nome deve ter pelo menos 6 caracteres').required('O nome é obrigatório'),
        email: Yup.string().email().required('O e-mail é obrigatório'),
        password: Yup.string().min(6, 'A senha deve conter pelo menos 6 caracteres').required('A senha é obrigatória'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas diferentes').required('A confirmação de senha é obrigatória'),
      });

      await shema.validate(data, { abortEarly: false });

      console.log(data);
      setUtils({ ...utils, loading: true, disabled: true });

      // loginMode ? login(values, dispatch) : signup(values, dispatch);
      signup(data, dispatch);
      
      formRef.current.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage = {};

        err.inner.forEach(error => {
          errorMessage[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessage);
      }
    }
    setUtils({ ...utils, loading: false, disabled: false });
  }

  function handleClickShowPassword() {
    setUtils({ ...utils, showPassword: !utils.showPassword });
  }

  function handleClickShowConfirmPassword() {
    setUtils({ ...utils, showConfirmPassword: !utils.showConfirmPassword });
  }

  return (
    <div className={classes.root}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Card className={classes.container}>
          <CardContent>
            <Typography variant='h2' className={classes.title}>Cadastro</Typography>
            <CustomTextField
              className={classes.textField}
              fullWidth
              label='Nome'
              name='name'
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            {/* <CustomTextField
              className={classes.textField}
              fullWidth
              label='Sobrenome'
              name='sobrenome'
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccountCircleOutlined />
                  </InputAdornment>
                ),
              }}
            /> */}
            <CustomTextField
              className={classes.textField}
              fullWidth
              label='Email'
              name='email'
              type='text'
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
            <CustomTextField
              className={classes.textField}
              fullWidth
              label='Senha'
              name='password'
              type={utils.showPassword ? 'text' : 'password'}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                    >
                      {utils.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )

              }}
            />
            <CustomTextField
              className={classes.textField}
              fullWidth
              label='Confirmação de Senha'
              name='confirmPassword'
              type={utils.showConfirmPassword ? 'text' : 'password'}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Smartphone />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowConfirmPassword}
                    >
                      {utils.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {/* <CustomTextField
              className={classes.textField}
              fullWidth
              label='Celular'
              name='phone'
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Smartphone />
                  </InputAdornment>
                ),
              }}
            /> */}
            <Button
              variant='contained'
              color='primary'
              fullWidth size='large'
              className={classes.btn}
              disabled={utils.disabled}
              type='submit'
            >
              {utils.loading ?
                <CircularProgress color='primary' size={24} /> :
                <Typography variant='button' color='inherit'>Criar</Typography>}
            </Button>
            <Typography variant='body1' color='textSecondary'>
              Já possui conta? <Link component={RouterLink} to='/sign-in' variant='h6'>Entrar</Link>
            </Typography>
          </CardContent>
        </Card>
      </Form>
    </div>
  );
}

export default SignUp;
import React from 'react';
import { makeStyles, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Typography } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { showUpdate, showBrotherList } from '../../brotherBoxActions';
import { update } from '../../../People/peopleActions';
import { dateConvert, currencyFormat, isArrayEmpty, returnCorrectListName } from '../../../../utils/functions';
import CustomDialog from '../../../../components/common/form/Dialog';
import { GROUPS } from '../../../../utils/consts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 505,
  },
  icon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
  text: {
    marginTop: theme.spacing(1)
  }
}));

function List() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const person = useSelector(state => state.brotherBox.person);
  const data = person.brotherBox;
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState({});

  const handleClickOpen = (brotherBox) => {
    setOpen(true);
    setItem(brotherBox);
  };

  const handleConfirm = () => {
    const anotherBrother = person.brotherBox.filter(obj => obj._id !== item._id);
    const newPerson = { ...person, brotherBox: anotherBrother }
    // console.log(newPerson);
    showBrotherList(dispatch, newPerson);
    update(newPerson, dispatch, () => { });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (brotherBox) => {
    showUpdate(dispatch, brotherBox);
  }

  const renderRow = () => {
    return isArrayEmpty(data) ? data.map(brotherBox => (
      <TableRow
        key={brotherBox._id}
        hover
      // onClick={() => handleClick(brotherBox)}
      >
        <TableCell>{dateConvert(brotherBox.date)}</TableCell>
        <TableCell>{brotherBox.history}</TableCell>
        <TableCell>{brotherBox.observation}</TableCell>
        <TableCell>{returnCorrectListName(brotherBox.group, GROUPS, 'value')}</TableCell>
        <TableCell>{currencyFormat(brotherBox.debt)}</TableCell>
        <TableCell>{currencyFormat(brotherBox.credit)}</TableCell>
        <TableCell>
          <Typography variant='body1' color={brotherBox.balance >= 0 ? 'secondary' : 'error'}>
            {currencyFormat(brotherBox.balance)}
          </Typography>
        </TableCell>
        {/* <TableCell>{currencyFormat(brotherBox.credit - brotherBox.debt)}</TableCell> */}
        <TableCell>
          {!brotherBox.payment &&
            <IconButton aria-label='edit' color='primary' size='small' onClick={() => handleClick(brotherBox)}>
              <EditIcon />
            </IconButton>
          }
          <IconButton aria-label='delete' color='secondary' className={classes.icon} size='small' onClick={() => handleClickOpen(brotherBox)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    )) :
      <TableRow>
        <TableCell>
          <Typography variant='h5' className={classes.text}>Este irmão não possuí registros.</Typography>
        </TableCell>
      </TableRow>
  }

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Histórico</TableCell>
            <TableCell>Obs</TableCell>
            <TableCell>Grupo</TableCell>
            <TableCell>Débito</TableCell>
            <TableCell>Crédito</TableCell>
            <TableCell>Saldo</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRow()}
        </TableBody>
      </Table>
      <CustomDialog open={open} handleClose={handleClose} handleConfirm={handleConfirm} context='Deseja realmente excluir esse item?' />
    </TableContainer>
  );
}

export default List;
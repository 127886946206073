import React, { useEffect, useRef } from 'react';
import { TextField } from '@material-ui/core';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { existsContent } from '../../../../utils/functions';

function CustomTextField({ label, name, type, mask, ...props }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  let { defaultValue } = useField(name);
  let initCpf = true;
  if (fieldName === 'cpfCnpj' && existsContent(defaultValue)) {
    initCpf = defaultValue.length <= 14 ? true : false
  }
  const [isCpf, setIsCpf] = React.useState(initCpf);
  let customMask = '';

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        if (mask === 'date') {
          // if (ref.value !== undefined || ref.value !== '')
          if (ref.value !== '') {
            // console.log(ref.value);
            const dateNow = new Date();
            // const date = new Date(ref.value);
            const date = moment(ref.value, ['DD/MM/YYYY', 'MM/DD/YYYY']).toDate();
            date.setHours(dateNow.getHours());
            date.setMinutes(dateNow.getMinutes());
            date.setSeconds(dateNow.getSeconds());
            // console.log(date);
            // const newDate = moment(ref.value).format();
            const newDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            // console.log(newDate);
            return newDate;
          } else return '';
        }
        return ref.value;
      }
    })
  }, [fieldName, registerField]);

  if (mask === 'date') {
    // console.log(defaultValue, name);
    if (defaultValue === undefined)
      defaultValue = '';
    else {
      const date = moment(defaultValue).format('DD/MM/YYYY');
      // console.log(date);
      defaultValue = date;
    }
  }

  const CustomTextFieldM = (
    <TextField
      inputRef={inputRef}
      {...props}
      label={label}
      name={name}
      type={type}
      error={error ? true : false}
      helperText={error}
      defaultValue={defaultValue}
    />
  );

  switch (mask) {
    case 'cpfCnpj':
      customMask = isCpf ? '999.999.999-999' : '99.999.999/9999-99'
      break;
    case 'cellphone':
      customMask = '(99) 99999-9999'
      break;
    case 'phone':
      customMask = '(99) 9999-9999'
      break;
    case 'postalCode':
      customMask = '99999-999'
      break;
    case 'date':
      customMask = '99/99/9999'
      break;
  }

  return (
    mask ?
      <InputMask
        mask={customMask}
        maskPlaceholder={null}
        onChange={e => {
          e.target.value.trim().length < 15 ? setIsCpf(true) : setIsCpf(false)
        }}
      >
        {CustomTextFieldM}
      </InputMask>
      : CustomTextFieldM
  );
}

export default CustomTextField;
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import consts, { ACCESS_USER_TYPES } from '../../utils/consts';
import { PEOPLE_FETCHED, PEOPLE_INIT, PEOPLE_FETCHED_TRASH } from '../../utils/types';

import { showTabs, selectTab } from '../../components/common/Tab/tabActions';

const INITIAL_VALUES = { address: {}, spouse: {}, accessData: {} };
// const INITIAL_VALUES = { address: {}, spouse: {}, accessData: { userType: ACCESS_USER_TYPES[0]._id } };

export function getList(dispatch) {
  axios.get(`${consts.API_URL}/people`)
    .then(resp => {
      dispatch({ type: PEOPLE_FETCHED, payload: resp });
    })
    .catch(e => console.log(e));
}

export function getTrash(dispatch) {
  axios.get(`${consts.API_URL}/people?trash=true`)
      .then(resp => {
        dispatch({ type: PEOPLE_FETCHED_TRASH, payload: resp });
      })
      .catch(e => console.log(e));
}

export function create(values, dispatch) {
  return submit(values, 'post', dispatch);
}

export function update(values, dispatch, callback) {
  return submit(values, 'put', dispatch, callback);
}

export function remove(values, dispatch) {
  return submit(values, 'delete', dispatch);
}


export function restore(values, dispatch) {
  return submitRestore(values, dispatch);
}

function submit(values, method, dispatch, callback) {
  const id = values._id ? values._id : '';
  axios[method](`${consts.API_URL}/people/${id}`, values)
    .then(resp => {
      callback ? callback(dispatch, resp.data) : init(dispatch, true);
      toastr.success('Sucesso', 'Operação Realizada com sucesso.');
    })
    .catch(e => {
      console.log(e);
      toastr.error('Erro', e.hasOwnProperty('response') && e.response.data);
      // toastr.error('Erro', e.response.data !== undefined ? e.response.data : e.response);
    });
}


function submitRestore(values, dispatch, callback) {
  const id = values._id ? values._id : '';
  axios.put(`${consts.API_URL}/people/${id}/restore`, values)
      .then(resp => {
        callback ? callback(dispatch, resp.data) : init(dispatch, true);
        toastr.success('Sucesso', 'Operação Realizada com sucesso.');
      })
      .catch(e => {
        console.log(e);
        toastr.error('Erro', e.hasOwnProperty('response') && e.response.data);
        // toastr.error('Erro', e.response.data !== undefined ? e.response.data : e.response);
      });
}

export function init(dispatch, reload) {
  dispatch([
    initPeople(dispatch, INITIAL_VALUES),
    showTabs(dispatch, 'tabList', 'tabCreate', 'tabTrash'),
    selectTab(dispatch, 0),
    reload && getList(dispatch),
    reload && getTrash(dispatch)
  ]);
}

export function showUpdate(dispatch, person) {
  dispatch([
    showTabs(dispatch, 'tabUpdate'),
    selectTab(dispatch, 3),
    initPeople(dispatch, person)
  ]);
}

export function initPeople(dispatch, value) {
  dispatch({ type: PEOPLE_INIT, payload: value });
}

import React, {useEffect} from 'react';
import {Button, CardActions, CircularProgress, Divider, makeStyles, Typography,} from '@material-ui/core';
import {useDispatch, useSelector,} from 'react-redux';
import consts, { OPTIONS_TABLE } from '../../../../utils/consts';
import Playlist from "react-mp3-player";
import {init, showPlayer, stopPlayer} from "../../playlistActions";

const useStyles = makeStyles((theme) => ({
  content: {
  },
  title: {
    marginBottom: 16
  },
  song: {
    marginBottom: 8
  },
  player: {
    marginTop: 60,
    marginBottom: theme.spacing(1)
  },
  btn: {
    width: '10%',
    minWidth: '100px'
  },
  cardActions: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

const options = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'title',
    direction: 'asc'
  }
};

function PlaylistPlayer() {
  const classes = useStyles();
  const playlist = useSelector(state => state.playlist.playlist);
  const dispatch = useDispatch();
  console.log(playlist)

  const playlistOverideStylingOpts = {
    offset : {
    },
    breakpoint : {
      maxWidth : 768
    }
  };


  if (!playlist || !playlist.songs) return null;

  function handleCancel() {
    init(dispatch, true);
  }

  function refreshPage(){ 
    window.location.reload(); 
}


  return (
      <div className={classes.content}>
        <Typography className={classes.title} variant={'h4'}>{playlist.description}</Typography>
        {
          playlist.songs.map(song => {
            return (
                <Typography className={classes.song} variant={'h6'}>{song.filename}</Typography>
            )
          })
        }
        <div  className={classes.player}>
        <Playlist tracks={playlist.songs.map(song => {
          return {
            img: 'https://icon-library.net/images/music-icon-transparent/music-icon-transparent-11.jpg',
            name:song.filename,
            src: `${consts.API_URL}/playlists-song/${song.key}`
          }
        })} opts={playlistOverideStylingOpts}/>
        </div>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button
              variant='contained'
              color='secondary'
              size='small'
              className={classes.btn}
              onClick={refreshPage}
          >
            Parar
          </Button>
        </CardActions>
      </div>
  );
}

export default PlaylistPlayer;

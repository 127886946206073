import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import consts from '../../utils/consts';
import {
  ACCOUNT_RECEIVABLE_FETCHED, ACCOUNT_RECEIVABLE_INIT, ACCOUNT_PAYABLE_FETCHED,
  ACCOUNT_PAYABLE_INIT, ACCOUNTS_MODE
} from '../../utils/types';

import { showTabs, selectTab } from '../../components/common/Tab/tabActions';

export function getList(dispatch, isRece) {
  const url = isRece ? `${consts.API_URL}/accountReceivable` :
    `${consts.API_URL}/accountPayable`
  axios.get(url)
    .then(resp => {
      dispatch({ type: isRece ? ACCOUNT_RECEIVABLE_FETCHED : ACCOUNT_PAYABLE_FETCHED, payload: resp });
    })
    .catch(e => console.log(e));
}

export function create(values, dispatch, accountsMode) {
  return submit(values, 'post', dispatch, accountsMode);
}

export function update(values, dispatch, accountsMode) {
  return submit(values, 'put', dispatch, accountsMode);
}

export function remove(values, dispatch, accountsMode) {
  return submit(values, 'delete', dispatch, accountsMode);
}

function submit(values, method, dispatch, accountsMode) {
  const id = values._id ? values._id : '';
  const url = accountsMode === 'aReceivable' ?
    `${consts.API_URL}/accountReceivable/${id}` :
    `${consts.API_URL}/accountPayable/${id}`;
  axios[method](url, values)
    .then(resp => {
      toastr.success('Sucesso', 'Operação Realizada com sucesso.');
      accountsMode === 'aReceivable' ?
        init(dispatch, true) :
        initPay(dispatch, true, true);
    })
    .catch(e => {
      toastr.error('Erro', e.response.data);
    });
}

export function init(dispatch, reload, isReceived) {
  dispatch([
    initAReceivable(dispatch, {}),
    showTabs(dispatch, 'tabListReceivable', 'tabListPayable', 'tabListReceived', 'tabCreate'),
    selectTab(dispatch, isReceived ? 2 : 0),
    reload && getList(dispatch, true)
  ]);
}

export function initPay(dispatch, reload, open) {
  dispatch([
    initAPayable(dispatch, {}),
    open && showTabs(dispatch, 'tabListReceivable', 'tabListPayable', 'tabListReceived', 'tabCreate'),
    open && selectTab(dispatch, 1),
    reload && getList(dispatch, false)
  ]);
}

export function showUpdate(dispatch, aReceivable, isReceived) {
  dispatch([
    showTabs(dispatch, 'tabUpdate'),
    selectTab(dispatch, 4),
    initAReceivable(dispatch, aReceivable),
    setAccountsMode(dispatch, isReceived ? 'aReceived' : 'aReceivable')
  ]);
}

export function showUpdatePay(dispatch, aPayable) {
  dispatch([
    showTabs(dispatch, 'tabUpdate'),
    selectTab(dispatch, 3),
    initAPayable(dispatch, aPayable),
    setAccountsMode(dispatch, 'aPayable')
  ]);
}

function initAReceivable(dispatch, value) {
  dispatch({ type: ACCOUNT_RECEIVABLE_INIT, payload: value });
}

function initAPayable(dispatch, value) {
  dispatch({ type: ACCOUNT_PAYABLE_INIT, payload: value });
}

export function setAccountsMode(dispatch, value) {
  dispatch({ type: ACCOUNTS_MODE, payload: value });
}

export function getBrotherList() {
  return axios.get(`${consts.API_URL}/brotherBox`)
    .catch(e => console.log(e));
}

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TreeView, TreeItem } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import {
  SupervisorAccount, AccountCircle, ShoppingCart, LocalLibrary, Create, Book, ExpandMore, ChevronRight,
  Dashboard, AttachMoney, AccountCircleOutlined, Work, Group, BusinessCenter, CalendarToday, LibraryMusic
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ACCESS_USER_TYPES } from '../../../../../utils/consts';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(0.5, 0),
    padding: theme.spacing(1, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, href, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color='inherit' className={classes.labelIcon} />
          <Typography variant='body2' className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant='caption' color='inherit'>
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  }
}));

function SidebarNavTree() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector(state => state.signIn);
  // const user = { userType: ACCESS_USER_TYPES[4]._id };
  // user['userLevel'] = '2';

  const handleSelect = (event, nodeIds) => {
    switch (nodeIds) {
      case 'dashboard':
        history.push('/dashboard');
        break;
      case 'book':
        history.push('/book');
        break;
      case 'people':
        history.push('/people');
        break;
      case 'client':
        history.push('/client');
        break;
      case 'provider':
        history.push('/provider');
        break;
      case 'calendar':
        history.push('/calendar');
        break;
      case 'playlist':
        history.push('/playlist');
        break;
      case 'lendBook':
        history.push('/lendBook');
        break;
      case 'accounts':
        history.push('/accounts');
        break;
      case 'brotherBox':
        history.push('/brotherBox');
        break;
      case 'myFinancial':
        history.push('/myFinancial');
        break;
      case 'myRegister':
        history.push('/myRegister');
        break;
      case 'requestBook':
        history.push('/lendBook', { isRequestBook: true });
        break;
      case 'apprentice':
        history.push('/works', { typeLevel: '1' });
        break;
      case 'companion':
        history.push('/works', { typeLevel: '2' });
        break;
      case 'mester':
        history.push('/works', { typeLevel: '3' });
        break;
    }
  };

  function renderSideBar(userType) {
    switch (userType) {
      case ACCESS_USER_TYPES[0]._id:
        return <>
          {renderMyFinancial()}
          {renderMyRegister()}
          {renderRequestBook()}
        </>;
      case ACCESS_USER_TYPES[1]._id:
        return <>
          {renderRegisters()}
          {renderFinancial()}
          {renderLibrary()}
        </>;
      case ACCESS_USER_TYPES[2]._id:
        return <>
          {renderFinancial()}
          {renderMyFinancial()}
          {renderMyRegister()}
          {renderRequestBook()}
        </>
      case ACCESS_USER_TYPES[3]._id:
        return <>
          {renderLibrary(true)}
          {renderMyFinancial()}
          {renderMyRegister()}
        </>;
      case ACCESS_USER_TYPES[4]._id:
        return <>
          {renderMyFinancial()}
          {renderRegisters(true)}
          {renderRequestBook()}
        </>;
      default:
        return <>
          {renderMyFinancial()}
          {renderMyRegister()}
          {renderRequestBook()}
        </>;
    }
  }

  function renderRegisters(onlyPeople) {
    return (
      onlyPeople ?
        <StyledTreeItem nodeId='register' labelText='Cadastro' labelIcon={Create}>
          <StyledTreeItem
            nodeId='people'
            labelText='Irmãos'
            labelIcon={SupervisorAccount}
            color='#e3742f'
            bgColor='#fcefe3'
          />
        </StyledTreeItem>
        :
        <StyledTreeItem nodeId='register' labelText='Cadastro' labelIcon={Create}>
          <StyledTreeItem
            nodeId='book'
            labelText='Livros'
            labelIcon={Book}
            // labelInfo='90'
            color='#1a73e8'
            bgColor='#e8f0fe'
          />
          <StyledTreeItem
            nodeId='people'
            labelText='Irmãos'
            labelIcon={SupervisorAccount}
            // labelInfo='194'
            color='#e3742f'
            bgColor='#fcefe3'
          />
          <StyledTreeItem
            nodeId='client'
            labelText='Cliente'
            labelIcon={AccountCircle}
            color='#3c8039'
            bgColor='#e6f4ea'
          />
          <StyledTreeItem
            nodeId='provider'
            labelText='Fornecedores'
            labelIcon={AccountCircleOutlined}
            color='#1a73e8'
            bgColor='#e8f0fe'
          />
          <StyledTreeItem
              nodeId='calendar'
              labelText='Calendario'
              labelIcon={CalendarToday}
              color='#1a73e8'
              bgColor='#e8f0fe'
          />
          <StyledTreeItem
              nodeId='playlist'
              labelText='Playlists'
              labelIcon={LibraryMusic}
              color='#1a73e8'
              bgColor='#e8f0fe'
          />
        </StyledTreeItem>
    );
  }

  function renderMyFinancial() {
    return (
      <StyledTreeItem
        nodeId='myFinancial'
        labelText='Meu Financeiro'
        labelIcon={AttachMoney}
        color='#3c8039'
        bgColor='#e6f4ea'
      />
    );
  }

  function renderMyRegister() {
    return (
      <StyledTreeItem
        nodeId='myRegister'
        labelText='Meus Dados'
        labelIcon={AccountCircle}
        color='#e3742f'
        bgColor='#fcefe3'
      />
    );
  }

  function renderFinancial() {
    return (
      <StyledTreeItem nodeId='moves' labelText='Financeiro' labelIcon={ShoppingCart}>
        <StyledTreeItem
          nodeId='brotherBox'
          labelText='Caixa dos Irmãos'
          labelIcon={SupervisorAccount}
          // labelInfo='2,294'
          color='#3c8039'
          bgColor='#e6f4ea'
        />
        <StyledTreeItem
          nodeId='accounts'
          labelText='Caixa da Loja'
          labelIcon={AttachMoney}
          color='#1a73e8'
          bgColor='#e8f0fe'
        />
      </StyledTreeItem>
    );
  }

  function renderLibrary(book) {
    return (
      book ?
        <>
          <StyledTreeItem
            nodeId='lendBook'
            labelText='Biblioteca'
            labelIcon={LocalLibrary}
            // labelInfo='2,294'
            color='#3c8039'
            bgColor='#e6f4ea'
          />
          <StyledTreeItem
            nodeId='book'
            labelText='Livros'
            labelIcon={Book}
            // labelInfo='90'
            color='#1a73e8'
            bgColor='#e8f0fe'
          />
        </>
        :
        <StyledTreeItem
          nodeId='lendBook'
          labelText='Biblioteca'
          labelIcon={LocalLibrary}
          // labelInfo='2,294'
          color='#3c8039'
          bgColor='#e6f4ea'
        />
    );
  }

  function renderRequestBook() {
    return (
      <StyledTreeItem
        nodeId='requestBook'
        labelText='Solicitar Livro'
        labelIcon={Book}
        color='#e3742f'
        bgColor='#fcefe3'
      />
    );
  }

  function renderWorks(userLevel) {
    function renderApprentice() {
      return (
        <StyledTreeItem
          nodeId='apprentice'
          labelText='Trabalhos de Aprendizes'
          labelIcon={AccountCircleOutlined}
          color='#3c8039'
          bgColor='#e6f4ea'
        />
      );
    }
    function renderCompanion() {
      return (
        <StyledTreeItem
          nodeId='companion'
          labelText='Trabalhos de Companheiros'
          labelIcon={Group}
          color='#1a73e8'
          bgColor='#e8f0fe'
        />
      );
    }
    function renderMester() {
      return (
        <StyledTreeItem
          nodeId='mester'
          labelText='Trabalhos de Mestres'
          labelIcon={BusinessCenter}
          color='#e3742f'
          bgColor='#fcefe3'
        />
      );
    }
    function renderLevels() {
      switch (userLevel) {
        case '1':
          return renderApprentice();
        case '2':
          return <>
            {renderApprentice()}
            {renderCompanion()}
          </>
        case '3':
          return <>
            {renderApprentice()}
            {renderCompanion()}
            {renderMester()}
          </>
      }
    }
    return renderLevels();
  }

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      onNodeSelect={handleSelect}
      defaultSelected='dashboard'
    >
      <StyledTreeItem nodeId='dashboard' labelText='Dashboard' labelIcon={Dashboard} />
      {renderSideBar(user.userType)}
      <StyledTreeItem nodeId='works' labelText='Trabalhos' labelIcon={Work}>
        {renderWorks(user.userLevel)}
      </StyledTreeItem>
    </TreeView>
  );
}

export default SidebarNavTree;

export default {
API_URL: 'https://app.pedromora78.com.br/api', // production
//API_URL: 'localhost:3003', // dev
};
export const SYSTEM_NAME = 'ARBLS Pedro Mora';
export const STATUS_BOOK_LIST = [
  { _id: 'AGUARDANDO CONFIRMACAO', value: 'Aguardando Confirmação' },
  { _id: 'EMPRESTADO', value: 'Emprestado' },
  { _id: 'NAO AUTORIZADO', value: 'Não Autorizado' },
  { _id: 'DEVOLVIDO', value: 'Devolvido' }
];
export const LEVEL_PERSON_LIST = [{ _id: null, value: 'Selecione um grau' }, { _id: 1, value: '1º Grau' }, { _id: 2, value: '2º Grau' }, { _id: 3, value: '3º Grau' }];
export const CELLPHONE_MASK = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const PERSON_SELECTED = { _id: null, value: 'Selecione uma pessoa' };
export const CLIENT_SELECTED = { _id: null, value: 'Selecione um cliente' };
export const BROTHER_SELECTED = { _id: 'IRMAO', value: 'Irmãos' };
export const STATUS_BROTHER_BOX_ACCOUNT = { _id: 'RECEBIDO', value: 'Recebido' };
export const PROVIDER_SELECTED = { _id: null, value: 'Selecione um fornecedor' };
export const BOOK_SELECTED = { _id: null, value: 'Selecione um livro' };
export const MARITAL_STATE = [
  { _id: 'SOLTEIRO', value: 'Solteiro(a)' },
  { _id: 'CASADO', value: 'Casado(a)' },
  { _id: 'DIVORCIADO', value: 'Divorciado(a)' },
  { _id: 'VIUVO', value: 'Viúvo(a)' },
];
export const GROUPS = [
  { _id: 'CAIXA IRMAOS', value: 'Caixa dos Irmãos' },
  { _id: 'CAIXA LOJA', value: 'Caixa da Loja' },
];
export const CARRIERS = [
  { _id: 'BANESTADO', value: 'Banestado' },
  { _id: 'BESC', value: 'Besc' },
  { _id: 'BRADESCO', value: 'Bradesco' },
  { _id: 'CAIXA ECONOMICA FEDERAL', value: 'Caixa Econômica Federal' },
  { _id: 'HSBC BAMERINDUS', value: 'HSBC Bamerindus' },
  { _id: 'ITAU', value: 'Itaú' },
  { _id: 'UNIBANCO', value: 'Unibanco' },
  { _id: 'CAIXA LOJA', value: 'Caixa Loja' },
];
export const PAYMENT_MODE = [
  { _id: 'A VISTA EM DINHEIRO', value: 'A Vista em Dinheiro' },
  { _id: 'A VISTA EM CHEQUE', value: 'A Vista em Cheque' },
  { _id: 'CARTAO AMERICAN EXPRESS', value: 'Cartão American Express' },
  { _id: 'CARTAO CREDICARD', value: 'Cartão Credicard' },
  { _id: 'CARTAO VISA', value: 'Cartão Visa' },
  { _id: 'CHEQUE PRE', value: 'Cheque Pré' },
  { _id: 'DIVIDA', value: 'Dívida' },
  { _id: 'OUTROS', value: 'Outros' },
];
export const ACCOUNTS_GROUPS = [
  { _id: 'ALUGUEIS', value: 'Aluguéis' },
  { _id: 'ANIMAIS', value: 'Animais' },
  { _id: 'BANCOS', value: 'Bancos' },
  { _id: 'BOLETO BANCARIO', value: 'Boleto Bancário' },
  { _id: 'CAIXA', value: 'Caixa' },
  { _id: 'CAIXA DOS IRMAOS', value: 'Caixa dos Irmãos' },
  { _id: 'CARRO', value: 'Carro' },
  { _id: 'CASA', value: 'Casa' },
  { _id: 'CONTAS', value: 'Contas' },
  { _id: 'EDUTACAO', value: 'Educação' },
  { _id: 'FILHOS', value: 'Filhos' },
  { _id: 'IMOVEIS', value: 'Imóveis' },
  { _id: 'IMPOSTO', value: 'Imposto' },
  { _id: 'LAZER', value: 'Lazer' },
  { _id: 'MOVEIS E UTENSILIOS', value: 'Móveis e Utensílios' },
  { _id: 'OUTRAS DESPESAS', value: 'Outras Despesas' },
  { _id: 'OUTRAS RECEITAS', value: 'Outras Receitas' },
  { _id: 'SALARIO', value: 'Salário' },
  { _id: 'SAUDE', value: 'Saúde' },
  { _id: 'TROCO DE BENEFICIENCIA', value: 'Troco de Beneficência' },
  { _id: 'VEICULOS', value: 'Veículos' },
];

export const OPTIONS_TABLE = {
  body: {
    noMatch: 'Nenhum registo encontrado.',
    toolTip: 'Ordernar',
    columnHeaderTooltip: column => `Ordernar por ${column.label}`
  },
  pagination: {
    next: 'Próxima',
    previous: 'Anterior',
    rowsPerPage: 'Resultados por página:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Pesquisar',
    downloadCsv: 'Download CSV',
    print: 'Imprimir',
    viewColumns: 'Mostrar Colunas',
    filterTable: 'Filtrar Tabela',
  },
  filter: {
    all: 'Todos',
    title: 'FILTROS',
    reset: 'LIMPAR',
  },
  viewColumns: {
    title: 'Mostrar Colunas',
    titleAria: 'Mostrar/Esconder Colunas da Tabela',
  },
  selectedRows: {
    text: 'linha(s) selecionada',
    delete: 'Remover',
    deleteAria: 'Remover Linha Selecionada',
  },
}
export const ACCESS_USER_TYPES = [
  { _id: 'IRMAO', value: 'Irmão' },
  { _id: 'VENERAVEL', value: 'Venerável' },
  { _id: 'TESOUREIRO', value: 'Tesoureiro' },
  { _id: 'BIBLIOTECARIO', value: 'Bibliotecário' },
  { _id: 'SECRETARIO', value: 'Secretário' },
  { _id: 'CHANCELER', value: 'Chanceler' },
];

import React, { useRef } from 'react';
import {
  makeStyles, CardContent, Button, Typography, Grid, InputAdornment, CircularProgress, CardActions, Divider,
} from '@material-ui/core';
import { Description as DescriptionIcon } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import * as Yup from 'yup';
import { cpf, cnpj } from 'cpf-cnpj-validator';

import { init, create, update, remove } from '../../clientActions';
import { STATUS_BOOK_LIST } from '../../../../utils/consts';
import CustomTextField from '../../../../components/common/form/CustomTextField';
import CustomSelect from '../../../../components/common/form/CustomSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  container: {
    maxHeight: 440,
    padding: theme.spacing(1)
  },
  btn: {
    width: '10%',
    minWidth: '100px'
  },
  cardContent: {
    paddingBottom: theme.spacing(3)
  },
  cardActions: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function ClientForm({ submitLabel }) {
  const classes = useStyles();
  const [utils, setUtils] = React.useState({
    loading: false,
    disabled: false,
  });
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const client = useSelector(state => state.client.client);

  async function handleSubmit(data) {
    try {
      const shema = Yup.object().shape({
        name: Yup.string().min(6, 'O nome deve ter pelo menos 6 caracteres').required('O nome é obrigatório'),
        cpfCnpj: Yup.string().test('isCpf', data.cpfCnpj.length > 14 ? 'CNPJ inválido!' : 'CPF inválido',
          value => data.cpfCnpj.length > 14 ? cnpj.isValid(value) : cpf.isValid(value))
          .required('O CPF/CNPJ é obrigatório'),
      });

      await shema.validate(data, { abortEarly: false });

      setUtils({ ...utils, loading: true, disabled: true });

      if (submitLabel === 'Incluir') create(data, dispatch);
      else if (submitLabel === 'Alterar') {
        data._id = client._id;
        update(data, dispatch);
      } else if (submitLabel === 'Excluir') remove(data, dispatch);
      formRef.current.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage = {};

        err.inner.forEach(error => {
          errorMessage[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessage);
      }
    }
    setUtils({ ...utils, loading: false, disabled: false });
  }

  function handleCancel() {
    init(dispatch, true);
  }

  function renderAddress() {
    return (
      <Scope path='address'>
        <Grid item md={6} xs={12}>
          <CustomTextField size='small' fullWidth label='Endereço' name='street' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Bairro' name='neighbor' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Número' name='number' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Complemento' name='complement' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Cidade' name='city' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Estado' name='state' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='CEP' name='postalCode' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Celular' name='cellphone' type='text' variant='outlined' />
        </Grid>
        <Grid item md={3} xs={12}>
          <CustomTextField size='small' fullWidth label='Telefone' name='phone' type='text' variant='outlined' />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomTextField size='small' fullWidth label='E-mail' name='email' type='text' variant='outlined' />
        </Grid>
      </Scope>
    );
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={client}>
      <div className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Nome'
                name='name'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='CPF/CNPJ'
                name='cpfCnpj'
                type='text'
                variant='outlined'
                mask='cpfCnpj'
                fieldType='mask'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='RG'
                name='rg'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Inscrição Estadual'
                name='stateRegistration'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Data de Nascimento'
                name='dateOfBirth'
                mask='date'
                type='text'
                variant='outlined'
              />
            </Grid>
            {renderAddress()}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className={classes.btn}
            disabled={utils.disabled}
            type='submit'
            disabled={utils.disabled}
          >
            {utils.loading ?
              <CircularProgress color='primary' size={24} /> :
              <Typography variant='button' color='inherit'>{submitLabel}</Typography>}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            className={classes.btn}
            onClick={handleCancel}
          >
            Cancelar
                        </Button>
        </CardActions>
      </div>
    </Form>
  );
}

export default ClientForm;
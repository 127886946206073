import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import consts from '../../utils/consts';
import { PROVIDER_FETCHED, PROVIDER_INIT } from '../../utils/types';

import { showTabs, selectTab } from '../../components/common/Tab/tabActions';

export function getList(dispatch) {
  axios.get(`${consts.API_URL}/providers`)
    .then(resp => {
      dispatch({ type: PROVIDER_FETCHED, payload: resp });
    })
    .catch(e => console.log(e));
}

export function create(values, dispatch) {
  return submit(values, 'post', dispatch);
}

export function update(values, dispatch) {
  return submit(values, 'put', dispatch);
}

export function remove(values, dispatch) {
  return submit(values, 'delete', dispatch);
}

function submit(values, method, dispatch) {
  const id = values._id ? values._id : '';
  axios[method](`${consts.API_URL}/providers/${id}`, values)
    .then(resp => {
      toastr.success('Sucesso', 'Operação Realizada com sucesso.');
      init(dispatch, true);
    })
    .catch(e => {
      toastr.error('Erro', e.response.data);
    });
}

export function init(dispatch, reload) {
  dispatch([
    initProvider(dispatch, {}),
    showTabs(dispatch, 'tabList', 'tabCreate'),
    selectTab(dispatch, 0),
    reload && getList(dispatch)
  ]);
}

export function showUpdate(dispatch, book) {
  dispatch([
    showTabs(dispatch, 'tabUpdate'),
    selectTab(dispatch, 2),
    initProvider(dispatch, book)
  ]);
}

function initProvider(dispatch, value) {
  dispatch({ type: PROVIDER_INIT, payload: value });
}
import React, { useRef } from 'react';
import {
  makeStyles, CardContent, Button, Typography, Grid, InputAdornment, CircularProgress, CardActions, Divider,
} from '@material-ui/core';
import { Book as BookIcon, Description as DescriptionIcon } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { init, create, update, remove } from '../../bookActions';
import { LEVEL_PERSON_LIST } from '../../../../utils/consts';
import CustomTextField from '../../../../components/common/form/CustomTextField';
import CustomSelect from '../../../../components/common/form/CustomSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  container: {
    maxHeight: 440,
    padding: theme.spacing(1)
  },
  btn: {
    width: '10%',
    minWidth: '100px'
  },
  cardContent: {
    paddingBottom: theme.spacing(3)
  },
  cardActions: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function List({ submitLabel }) {
  const classes = useStyles();
  const [utils, setUtils] = React.useState({
    showPassword: false,
    loading: false,
    disabled: false,
  });
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const book = useSelector(state => state.book.book);

  async function handleSubmit(data) {
    try {
      const shema = Yup.object().shape({
        title: Yup.string().required('O título é obrigatório'),
        description: Yup.string().min(10, 'A descrição deve ter pelo menos 10 caracteres').required('A descrição é obrigatória'),
        amount: Yup.string().required('O estoque é obrigatório'),
        level: Yup.string().required('O grau é obrigatório')
      });

      await shema.validate(data, { abortEarly: false });

      setUtils({ ...utils, loading: true, disabled: true });
      // setTimeout(() => {
      //     console.log(data);
      //     setUtils({ ...utils, loading: false, disabled: false });
      // }, 3000);

      if (submitLabel === 'Incluir') create(data, dispatch);
      else if (submitLabel === 'Alterar') {
        data._id = book._id;
        update(data, dispatch);
      } else if (submitLabel === 'Excluir') remove(data, dispatch);
      formRef.current.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage = {};

        err.inner.forEach(error => {
          errorMessage[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessage);
      }
    }
    setUtils({ ...utils, loading: false, disabled: false });
  }

  function handleCancel() {
    init(dispatch, true);
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={book}>
      <div className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Título*'
                name='title'
                type='text'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <BookIcon fontSize='small' />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomTextField
                name='author'
                variant='outlined'
                className={classes.textField}
                label='Autor(es)'
                fullWidth
                size='small'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomSelect
                name='level'
                variant='outlined'
                className={classes.formControl}
                label='Grau*'
                fullWidth
                size='small'
                options={LEVEL_PERSON_LIST}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Estoque*'
                name='amount'
                type='text'
                variant='outlined'
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Descrição*'
                name='description'
                multiline
                rows={4}
                type='text'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <DescriptionIcon fontSize='small' />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomTextField
                className={classes.textField}
                size='small'
                fullWidth
                label='Observação'
                name='observation'
                type='text'
                variant='outlined'
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className={classes.btn}
            disabled={utils.disabled}
            type='submit'
            disabled={utils.disabled}
          >
            {utils.loading ?
              <CircularProgress color='primary' size={24} /> :
              <Typography variant='button' color='inherit'>{submitLabel}</Typography>}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            className={classes.btn}
            onClick={handleCancel}
          >
            Cancelar
                        </Button>
        </CardActions>
      </div>
    </Form>
  );
}

export default List;
import {
  ACCOUNT_RECEIVABLE_FETCHED, ACCOUNT_RECEIVABLE_INIT, ACCOUNT_PAYABLE_FETCHED,
  ACCOUNT_PAYABLE_INIT, ACCOUNTS_MODE
} from '../../utils/types';

const INITIAL_STATE = {
  listReceivable: [],
  listPayable: [],
  accountReceivable: {},
  accountPayable: {},
  accountsMode: 'aReceivable'
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACCOUNT_RECEIVABLE_FETCHED:
      return { ...state, listReceivable: action.payload.data.data };
    case ACCOUNT_PAYABLE_FETCHED:
      return { ...state, listPayable: action.payload.data.data };
    case ACCOUNT_RECEIVABLE_INIT:
      return { ...state, accountReceivable: action.payload };
    case ACCOUNT_PAYABLE_INIT:
      return { ...state, accountPayable: action.payload };
    case ACCOUNTS_MODE:
      return { ...state, accountsMode: action.payload };
    default:
      return state;
  }
};

import { LEND_BOOK_FETCHED, LEND_BOOK_INIT } from '../../utils/types';

const INITIAL_STATE = { list: [], lendBook: {} };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LEND_BOOK_FETCHED:
      return { ...state, list: action.payload };
    case LEND_BOOK_INIT:
      return { ...state, lendBook: action.payload };
    default:
      return state;
  }
};

import React from 'react';
import { makeStyles, CircularProgress, IconButton } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon, CloudDownload } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import { isArrayEmpty, convertSize } from '../../../../utils/functions';
import { showUpdate, downloadFile } from '../../worksActions';
import { OPTIONS_TABLE, ACCESS_USER_TYPES } from '../../../../utils/consts';

const useStyles = makeStyles((theme) => ({
  containerProgress: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    marginRight: theme.spacing(2)
  }
}));

const options = {
  filterType: 'checkbox',
  elevation: 0,
  selectableRowsHideCheckboxes: true,
  selectableRowsHeader: false,
  selectableRows: 'none',
  textLabels: OPTIONS_TABLE,
  sortOrder: {
    name: 'name',
    direction: 'asc'
  }
};

function List({ data, userType }) {
  const classes = useStyles();
  const columns = [
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'level',
      label: 'Grau',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'filename',
      label: 'Nome do Arquivo',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'size',
      label: 'Tamanho',
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (index) => {
          return (
            <td key={index}>
              {convertSize(data[index].size)}
            </td>
          );
        }
      },
    },
    {
      name: 'actions',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const handleClick = () => {
            showUpdate(dispatch, data[dataIndex]);
          }

          const handleClickDownload = () => {
            downloadFile(data[dataIndex]._id, data[dataIndex].filename);
          }

          return (
            <>
              {userType === ACCESS_USER_TYPES[1]._id &&
                <IconButton aria-label='edit' color='primary' size='small' onClick={handleClick} className={classes.button}>
                  <EditIcon />
                </IconButton>
              }
              <IconButton aria-label='download' color='secondary' size='small' onClick={handleClickDownload}>
                <CloudDownload />
              </IconButton>
            </>
          );
        }
      }
    },
  ];

  const dispatch = useDispatch();

  const renderProgress = () => (
    <div className={classes.containerProgress}>
      <CircularProgress color='secondary' />
    </div>
  );

  return (
    !isArrayEmpty(data) ?
      renderProgress() :
      <MUIDataTable
        data={data}
        columns={columns}
        options={options}
      />
  );
}

export default List;